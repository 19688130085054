import React, { Component } from 'react'; //different
import './App.css';
import Footer from './footer';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
import instance, { preoductionurl } from "./axios-instance";
import { NumericFormat } from 'react-number-format';
import Systems from "./SystemFunction";
import moment from 'moment';
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            activePage: 1,
            pagecount: 1,
            products_page_count: 1,
            isActive: false,
            activeTab: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: true, // Indicates in progress state of login form
            userid: null,
            point: 0,
            balance: 0,
            loginstate: null,
            firstname: null,
            lastname: null,
            alert: null,
            modalOpen: false,
            handleClose: false,
            proderedeemlist: [],
            token: window.localStorage.getItem("token"),
            type: "",
            username: "",
            amount: "",
            remark: "",
            mainacc: "",
            rankpic: "1.jpeg",
            rankname: null,
            rankob: null,
            rankpoint: 0,
            name: "",
            password: "",
            linelink: null,
            left: false,
        }
    }
    logout = (e) => {

        // window.location.href = '/logout';

    }
    async componentDidMount() {
        await instance.post("/api/v1/mainsetting", {
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                const datas = res.data.message;
                this.setState({
                    linelink: datas[1].value,
                });
            }
            else {
                // this.CheckThisGoalError();
            }
        });
        var userAgent = navigator.userAgent.toLowerCase()
        // if (userAgent.indexOf('safari') != -1) {
        //     if (userAgent.indexOf('chrome') > -1) {
        //     } else {
        //         await Swal.fire({
        //             title: 'Warning!',
        //             text: 'แนะนำให้ใช้งานบน Google Chorme เพื่อประสิทธิภาพที่ดีกว่า',
        //             icon: 'warning',
        //             confirmButtonText: 'ตกลง'
        //         })
        //     }
        // }

        try {
            var token = localStorage.getItem('auth_token');
            //  //////console.log(token);
            if (token != null) {
                var decoded = jwt_decode(token);
                var Username = decoded.message.playerid;
                await this.rechecklogin(Username, token);
                this.setState({
                    userid: decoded.message.playerid,
                    username: decoded.message.playerid,
                    firstname: decoded.message.firstname,
                    lastname: decoded.message.lastname,
                    loginstate: true,
                });
            }
            else {
                this.setState({
                    loginstate: false,
                });
            }
        }
        catch (error) {
            //////console.log();
        }

    }
    login = (e) => {
        e.preventDefault();
        let errors = this.validateLoginForm();
    }
    onChange = event => {
        let nam;
        let val;
        if (event.target.name === undefined) {
            // //////console.log("undefined", event.target);
            nam = event.name;
            val = event.value;
        } else {
            // //////console.log("event", event.target);
            nam = event.target.name;
            val = event.target.value;
        }
        this.setState({ [nam]: val });

    };
    validateLoginForm = async (e) => {
        const { username, password } = this.state;
        //////console.log(username, password);
        if (username != "" && password != "") {
            instance.post("/api/v1/userlogin", {
                userid: username,
                password: password,
                System: Systems,
                Function: 'validateLoginForm-navbar.js'
            })
                .then((res) => {
                    console.log(username, password);
                    if (res.status === 200) {
                        console.log(res.data.status);
                        if (res.data.status === 200) {
                            localStorage.setItem('auth_token', res.data.token)
                            this.setState({
                                userid: res.data.message.member_id,
                                username: res.data.message.playerid,
                                firstname: res.data.message.firstname,
                                lastname: res.data.message.lastname,
                                point: res.data.message.Point,
                                balance: res.databalance,
                                loginstate: true,
                            });
                            window.location.replace(preoductionurl() + '?login=' + res.data.token);
                            // window.location.replace('https://play.trixie168.com?login=' + res.data.token);
                        }
                        else if (res.data.status === 401) {
                            Swal.fire({
                                title: 'error!',
                                text: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                        else {
                            Swal.fire({
                                title: 'error!',
                                text: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                            //   Bonn.alert("ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง")
                        }
                    }
                    else {
                        this.setState({
                            firstname: null,
                            lastname: null,
                            point: null,
                            loginstate: false,
                        });
                        var title = 'Error!'
                        var text = 'Username นี้ทำการสมัครไปแล้ว'
                        var object = { title, text }
                        this.CheckThisGoalError(object);
                    }
                });
        } else {
            Swal.fire({
                title: 'error!',
                text: 'คุณไม่ได้ใส่ชื่อ หรือรหัสผ่าน',
                icon: 'error',
                confirmButtonText: 'ตกลง'
            })
        }
    }
    async componentDidMount() {
        try {
            await instance.post("/api/v1/mainsetting", {
                System: Systems,
            }).then((res) => {
                if (res.data.status === 200) {
                    const datas = res.data.message;
                    this.setState({
                        linelink: datas[1].value,
                        System: res.data.tblcatagoryx[0].System
                    });
                }
            });
        }
        catch (error) {
            //////console.log();
        }
    }
    openAccout(evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].className += " -open";
        }
    }

    cancelredeempromotion = async (e, index) => {
        e.preventDefault();
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            Swal.fire({
                icon: 'warning',
                title: 'คุณต้องการยกเลิกการใช้โปรใช่ หรือ ไม่?',
                showCancelButton: true,
                confirmButtonText: 'Save',
            }).then(async (result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    await instance.post("/api/v1/usercancelpromotion", {
                        User_id: Username,
                        System: Systems,
                        no: index.no,
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    }).then(async (res) => {
                        if (res.data.status === 200) {
                            this.checkpro(null, 999);
                            Swal.fire('ดำเนินการเรียบร้อย', '', 'success')
                        }
                        else {
                            Swal.fire('ไม่สามารถดำเนินการได้ โปรดรอซักครู่', '', 'error')
                        }
                    });

                }
            })
        }

    }

    openAccoutcan(evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("x-menu-account-list-sidebar");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].className = "x-menu-account-list-sidebar";
        }
    }

    async rechecklogin(Username, token) {

        try {
            // console.log(4);
            instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'rechecklogin-navbar.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //  console.log(res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        rankpic: res.data.tblrank.image,
                        rankname: res.data.tblrank.name,
                        rankob: res.data.tblrank,
                        rankpoint: res.data.rankpoint,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                console.log(err)
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }

    }
    checkpro = async (e, proid) => {
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        await instance.post("/api/v1/checkpromotion", {
            userid: Username,
            System: Systems,
            Proid: proid,
        }, {
            headers: { Authorization: `Bearer ${token}` }
        }).then(async (res) => {
            if (res.data.status === 200) {
                //console.log(res.data)
                // console.log(res.data.message.tblpromotionredeems)
                if (res.data.type === 0) {
                    this.setState({
                        prodetail: res.data.message.tblpromotions,
                    });
                }
                else if (res.data.type === 1) {
                    this.setState({
                        proderedeemlist: res.data.message.tblpromotionredeems,
                    });

                }

            }
            else if (res.data.status === 201) {
                await Swal.fire({
                    title: 'error!',
                    text: 'ท่านไม่เข้าเงื่อนใขโปรโมชั่นนี้',
                    icon: 'error',
                    confirmButtonText: 'ตกลง'
                })
                return false;
            }
            else if (res.data.status === 202) {
                await Swal.fire({
                    title: 'error!',
                    text: 'ท่านใช้โปรโมชั่นค้างไว้ สามารถเติมเงินได้เลย',
                    icon: 'error',
                    confirmButtonText: 'ตกลง'
                })
                return false;
            }

        }).catch(async (err) => {
            // console.log(err);
            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await Swal.fire({
                    title: 'Warning!',
                    text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
                window.location.href = '/';
            }
        });
    }


    validateLogout = (e) => {
        localStorage.removeItem('auth_token', '')
        this.setState({
            userid: null,
            username: null,
            password: null,
            firstname: null,
            lastname: null,
            point: null,
            loginstate: false,
        });
    }
    async recheccredit(evt) {

        evt.currentTarget.className += " fa-spin";
        var token = localStorage.getItem('auth_token');
        var decoded = jwt_decode(token);
        var Username = decoded.message.playerid;
        //  this.rechecklogin(Username, token);
        this.setState({
            userid: decoded.message.playerid,
            username: decoded.message.playerid,
            firstname: decoded.message.firstname,
            lastname: decoded.message.lastname,
            loginstate: true,
        });
        try {
            //  console.log(5);
            await instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'recheccredit-navbar.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //console.log(res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                    var i, tablinks;
                    tablinks = document.getElementsByClassName("-btn-balance");
                    for (i = 0; i < tablinks.length; i++) {
                        tablinks[i].className = tablinks[i].className.replace(" fa-spin", "");
                    }
                }
                else if (res.data.status === 401) {
                    localStorage.clear();
                    //   console.log(res.data);
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                //    console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }
    }
    logout = (e) => {
        // e.preventDefault();
        let errors = this.validateLogout();
        if (errors === true) {
            // alert("You are successfully signed in...");
            window.location.reload()
        } else {

            this.setState({
                errors: errors,
                formSubmitted: true
            });
            window.location.reload()
        }
    }
    render() {

        const { loginstate, userid, username, point, firstname, lastname, balance, proderedeemlist } = this.state
        // //////console.log(this.state);
        return (
            <div >
                <button class="wrapper-menu  sidebarCollapse" aria-label="Main Menu">
                    <svg width="40" height="40" viewBox="0 0 100 100">
                        <path class="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"></path>
                        <path class="line line2" d="M 20,50 H 80"></path>
                        <path class="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"></path>
                    </svg>
                </button>
                <div className="navbarmain">
                    <div className="logo">
                        <a
                            href="/"
                            className="custom-logo-link"
                            rel="home"
                        >
                            <img
                                width={400}
                                height={110}
                                src="/build/web/ez-bet/img/logo-black.png?v=1"
                                className="custom-logo"
                                alt="BLUEWHITE"
                                decoding="async"
                                srcSet="/build/web/ez-bet/img/logo-black.png?v=1 400w, /build/web/ez-bet/img/logo-black.png?v=1 300w, /build/web/ez-bet/img/logo-black.png?v=1 24w, /build/web/ez-bet/img/logo-black.png?v=1 36w, /build/web/ez-bet/img/logo-black.png?v=1 48w"
                                sizes="(max-width: 400px) 100vw, 400px"
                            />
                        </a>{" "}
                    </div>
                    <div className="menuicon ">
                        <div className="menu-menu-right-container">
                            <ul id="menu-menu-right" className="menu">
                                <li
                                    id="menu-item-1471"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1471"
                                >
                                    <a
                                        href="#"
                                        className="menu-image-title-after menu-image-not-hovered"
                                    >
                                        <img
                                            width={100}
                                            height={100}
                                            src="/build/web/igame-index-lobby-wm/img/download-1.png"
                                            className="menu-image menu-image-title-after"
                                            alt=""
                                            decoding="async"
                                        />
                                        <span className="menu-image-title-after menu-image-title">
                                            โปรโมชั่น
                                        </span>
                                    </a>
                                </li>
                                <li
                                    id="menu-item-1577"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1577"
                                >
                                    <a
                                        href="#"
                                        className="menu-image-title-after menu-image-not-hovered"
                                    >
                                        <img
                                            width={200}
                                            height={200}
                                            src="/build/web/igame-index-lobby-wm/img/menu-hot-ic2.png"
                                            className="menu-image menu-image-title-after"
                                            alt=""
                                            decoding="async"
                                        />
                                        <span className="menu-image-title-after menu-image-title">
                                            บทความ
                                        </span>
                                    </a>
                                </li>
                                <li
                                    id="menu-item-1472"
                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1472"
                                >
                                    <a href={this.state.linelink} className="menu-image-title-after menu-image-not-hovered">
                                        <img
                                            width={512}
                                            height={512}
                                            src="/build/web/igame-index-lobby-wm/img/line.png"
                                            className="menu-image menu-image-title-after"
                                            alt=""
                                            decoding="async"
                                            fetchpriority="high"
                                        />
                                        <span className="menu-image-title-after menu-image-title">
                                            ติดต่อเรา
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>{" "}
                        <div className="overlaymenu" />
                    </div>
                    <div className="toploginbox">
                        <form>
                            <div className="el-input my-1">
                                <i className="fas fa-user" />
                                <input
                                    type="text"
                                    id="username"
                                    inputMode="text"
                                    onChange={this.onChange}
                                    name="username"
                                    pattern="[0-9]*"
                                    autoFocus=""
                                    className="inputstyle"
                                    placeholder="เบอร์โทรศัพท์"
                                />
                            </div>
                            <div className="el-input my-1">
                                <i className="fas fa-lock" />
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    onChange={this.onChange}
                                    className="inputstyle"
                                    placeholder="รหัสผ่าน"
                                />
                            </div>
                            <div className="flexcenter">
                                <button type="submit"
                                    onClick={(e) => {
                                        this.login(e)
                                    }} className="btn">
                                    <span>เข้าสู่ระบบ</span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="loginregishead">
                        <button className="btn btn-primary mt-lg-3 mr-lg-3">
                            <div className="-text-container">
                                {" "}
                                <div className="textwidget">
                                    <a data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">เข้าสู่ระบบ</a>
                                </div>
                            </div>
                        </button>
                        <button className="btn btn-primary mt-lg-3 mr-lg-3">
                            <div className="-text-container">
                                {" "}
                                <div className="textwidget">
                                    <a href="/register">สมัครสมาชิก</a>
                                </div>
                            </div>
                        </button>{" "}
                    </div>
                </div>


            </div>
        );
    }
}
export default App;
