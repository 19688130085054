import React, { Component } from 'react'; //different
//import './style.css';
import NavMenu from './navmenu';
import Lowmenu from './lowmenu';
import Listmenu from './listmenu';
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import instance, { preoductionurl } from "./axios-instance";
import Swal from 'sweetalert2'
import { isMobile } from 'react-device-detect';
import Systems from "./SystemFunction";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
var settings = {
    dots: true,
    infinite: true,
    slidesToShow: (window.innerWidth <= 760) ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear"
};
class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            slotlist: [],
            recentlist: [],
            rewardlist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            recentmenu: false,
            playerid: null,
            username: null,
            prolistx: [],
            user_id: null,
            point: null,
            userid: null,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            modal: false,
            alert: null,
            typepage: null,
            linelink: null,
        }
        this.handleClick = this.handleClick.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.hideAlert = this.hideAlert.bind(this);

    }
    async saveclick(linkid) {
        await instance.post("/api/v1/saveclick", {
            linkid: linkid,
            System: Systems,
        }).then((res) => {
        });

    }

    modalsingin() {

    }

    handlePageChange(pageNumber) {
        //////console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
        instance
            .get("/api/v1/rewardlist", {
                params: {
                    Cat_ID: 0,
                    page: pageNumber
                }
            })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
    }
    redeem = (e, gameIdx, partner) => {
        var userAgent = navigator.userAgent
        this.checklogin();
        if (!this.state.loginstate) {
            return document.getElementById("loginmode").click();
        }
        if (this.state.username !== null) {
            instance.post("/api/v1/tablauncher", {
                gameId: gameIdx,
                partner: partner,
                playerUsername: this.state.username,
                isMobile: isMobile,
                System: Systems,
                demo: false
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        //////console.log(res.data.message);
                        var test = res.data.message.launcher
                        if (test !== undefined) {
                            if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                                window.location.href = test.mobile;
                            }
                            else {
                                if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                                else window.open(test.mobile);
                            }
                        }
                        else if (test === undefined) {
                            Swal.fire({
                                title: 'error!',
                                text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                    }
                    else {
                        //  this.CheckThisGoalError();
                    }
                });
        }
        else {

        }


    }

    handleClick = async (e) => {
        this.setState({
            activePage: 1,
            isActive: true,
            activeTab: 0
        });
        instance.get("/api/v1/rewardlist", {
            params: {
                Cat_ID: e.target.id,
                page: 1
            }
        })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
        this.setState({
            isActive: false
        });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }



    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            this.rechecklogin(Username, token);
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }
    login = (e) => {
        e.preventDefault();
        let errors = this.validateLoginForm();
    }
    onChange = event => {
        let nam;
        let val;
        if (event.target.name === undefined) {
            // //////console.log("undefined", event.target);
            nam = event.name;
            val = event.value;
        } else {
            // //////console.log("event", event.target);
            nam = event.target.name;
            val = event.target.value;
        }
        this.setState({ [nam]: val });

    };
    validateLoginForm = async (e) => {
        const { username, password } = this.state;
        //////console.log(username, password);
        if (username != "" && password != "") {
            instance.post("/api/v1/userlogin", {
                userid: username,
                password: password,
                System: Systems,
                Function: 'validateLoginForm-navbar.js'
            })
                .then((res) => {
                    console.log(username, password);
                    if (res.status === 200) {
                        console.log(res.data.status);
                        if (res.data.status === 200) {
                            localStorage.setItem('auth_token', res.data.token)
                            this.setState({
                                userid: res.data.message.member_id,
                                username: res.data.message.playerid,
                                firstname: res.data.message.firstname,
                                lastname: res.data.message.lastname,
                                point: res.data.message.Point,
                                balance: res.databalance,
                                loginstate: true,
                            });
                            window.location.replace(preoductionurl() + '?login=' + res.data.token);

                           // window.location.replace('https://play.trixie168.com?login=' + res.data.token);
                        }
                        else if (res.data.status === 401) {
                            Swal.fire({
                                title: 'error!',
                                text: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                        }
                        else {
                            Swal.fire({
                                title: 'error!',
                                text: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                            //   Bonn.alert("ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง")
                        }
                    }
                    else {
                        this.setState({
                            firstname: null,
                            lastname: null,
                            point: null,
                            loginstate: false,
                        });
                        var title = 'Error!'
                        var text = 'Username นี้ทำการสมัครไปแล้ว'
                        var object = { title, text }
                        this.CheckThisGoalError(object);
                    }
                });
        } else {
            Swal.fire({
                title: 'error!',
                text: 'คุณไม่ได้ใส่ชื่อ หรือรหัสผ่าน',
                icon: 'error',
                confirmButtonText: 'ตกลง'
            })
        }
    }
    async rechecklogin(Username, token) {
        try {
            //console.log(1, Username, Systems, token);
            instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Function: 'rechecklogin-Main.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                //  console.log(181,res.data);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token)
                    this.setState({
                        tblnextrank: res.data.tblnextrank,
                        tblprofile: res.data.message,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fullname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    //  console.log(res.data);
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            //////console.log(ex);
        }

    }

    async componentDidMount() {
        const search = window.location.href;
        var test = search.split("aff=")[1]
        if (search.split("/").length > 3) {
            console.log(334, search.split("/")[3])
            if (search.split("/")[3] != '') {
                this.setState({
                    typepage: search.split("/")[3],
                });
            }
            else {
                this.setState({
                    typepage: "main",
                });
            }

        }
        else {
            this.setState({
                typepage: "main",
            });
        }
        if (test != undefined) {
            this.saveclick(test)
        }
        await instance.post("/api/v1/mainsetting", {
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                const datas = res.data.message;
                this.setState({
                    linelink: datas[1].value,
                    System: res.data.tblcatagoryx[0].System
                });
            }
            else {
                // this.CheckThisGoalError();
            }
        });
        this.promotionlist();
        var Username;
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            Username = decoded.message.playerid;
        }
        await instance.post("/api/v1/tabgamelistpop", {
            state: "2",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        categorylist: res.data.message
                    });
                    //////console.log(res.data.message);
                }
                else {
                    // this.CheckThisGoalError();
                }
            });

        await instance.post("/api/v1/tabgamelistpop", {
            state: "3",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        slotlist: res.data.message
                    });
                    //////console.log(res.data.message);
                }
                else {
                    // this.CheckThisGoalError();
                }
            });

        await instance.post("/api/v1/tabgamelistpop", {
            state: "10",
            System: Systems,
            playerid: Username

        })
            .then((res) => {
                if (res.data.status === 200) {

                    // this.state.recentmenu
                    this.setState({
                        recentlist: res.data.message,
                        recentmenu: true
                    });
                    //////console.log(555, res.data.message);
                }
                else {
                    // this.CheckThisGoalError();
                }
            });

        await this.checklogin();
    }
    addDefaultSrc(ev, listitem) {
        const origin = listitem.split("http");
        //  console.log("img", origin.length )
        if (origin.length > 1) {
            return listitem;
        }
        else {
            return "https://ardmhzelxcmj.ocrazeckyunc.com" + listitem

        }

    }
    promotionlist = () => {
        instance.post("/api/v1/getpromotion", {
            System: Systems,
        }).then(async (res) => {
            if (res.data.status === 200) {
                this.setState({
                    prolistx: res.data.message,
                });
            }
        }).catch(async (err) => {
            //   console.log(err);

            if (err.response.status === 401 || err.response.status === 403) {
                localStorage.clear();
                await Swal.fire({
                    title: 'Warning!',
                    text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                    icon: 'warning',
                    confirmButtonText: 'ตกลง'
                })
                window.location.href = '/';
            }
        });
    }
    render() {
        const { prodetail, picnonpro, username, prolistx, typepage } = this.state
        console.log(typepage);
        return (
            <>
                <div className="fixedtopmobile">
                    <div className="newsboxhead" data-delat={200}>
                        <div className="-icon-container">
                            <i className="fas fa-volume-up" />
                        </div>
                        <span>
                            Trixie168 คาสิโนออนไลน์ สล็อตออนไลน์ ที่ให้คุณสนุกได้ทุกรูปแบบ
                            <span>
                                {" "}
                                <div className="textwidget" />
                            </span>
                        </span>
                    </div>
                    <div className="swiper-container-2 swiper-initialized swiper-horizontal swiper-backface-hidden">
                        <Slider {...settings}>
                            <div onClick={(e) => window.location.href = '/register'}
                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                                data-slick-index={-2}
                                style={{ marginRight: "10px" }}
                                aria-hidden="true"
                                tabIndex={-1}
                            >
                                <div className="-link-wrapper">
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-9"
                                            alt="ถูกผิด 10 ตาติดรับเครดิตทันที 5000"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/687fc8ae-ea57-47e2-8546-c3584e19a7b9.jfif"
                                        />
                                    </picture>
                                </div>
                            </div>
                            <div
                                onClick={(e) => window.location.href = '/register'}
                                className="-slide-inner-wrapper -slick-item slick-slide slick-cloned"
                                data-slick-index={-1}
                                style={{ marginRight: "10px" }}
                                aria-hidden="true"
                                tabIndex={-1}
                            >
                                <a
                                    className="-link-wrapper"
                                    href="/promotions"
                                    target="_blank"
                                    rel="nofollow noopener"
                                    tabIndex={-1}
                                >
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-10"
                                            alt="แนะนำเพื่อน"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/0799eb1d-70a2-4a6f-96be-126d390bd989.jfif"
                                        />
                                    </picture>
                                </a>
                            </div>
                            <div
                                onClick={(e) => window.location.href = '/register'}
                                className="-slide-inner-wrapper -slick-item slick-slide"
                                data-slick-index={0}
                                aria-hidden="true"
                                style={{ marginRight: "10px" }}
                                role="tabpanel"
                                id="slick-slide00"
                                aria-describedby="slick-slide-control00"
                                tabIndex={-1}
                            >
                                <div className="-link-wrapper">
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-10"
                                            alt="แนะนำเพื่อน"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/9384e873-f380-427e-be5b-6faccd0d8007.jfif"
                                        />
                                    </picture>
                                </div>
                            </div>
                            <div
                                onClick={(e) => window.location.href = '/register'}
                                className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                                data-slick-index={-2}
                                style={{ marginRight: "10px" }}
                                aria-hidden="true"
                                tabIndex={-1}
                            >
                                <div className="-link-wrapper">
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-9"
                                            alt="ถูกผิด 10 ตาติดรับเครดิตทันที 5000"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/a3d6886b-bd18-41e4-83b5-cb2fb58721b9.jfif"
                                        />
                                    </picture>
                                </div>
                            </div>
                            <div
                                onClick={(e) => window.location.href = '/register'}
                                className="-slide-inner-wrapper -slick-item slick-slide slick-cloned"
                                data-slick-index={-1}
                                style={{ marginRight: "10px" }}
                                aria-hidden="true"
                                tabIndex={-1}
                            >
                                <a
                                    className="-link-wrapper"
                                    href="/promotions"
                                    target="_blank"
                                    rel="nofollow noopener"
                                    tabIndex={-1}
                                >
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-10"
                                            alt="แนะนำเพื่อน"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/fb64199b-9741-4567-a653-991c3c5c70e4.jfif"
                                        />
                                    </picture>
                                </a>
                            </div>
                            <div
                                onClick={(e) => window.location.href = '/register'}
                                className="-slide-inner-wrapper -slick-item slick-slide"
                                data-slick-index={0}
                                aria-hidden="true"
                                style={{ marginRight: "10px" }}
                                role="tabpanel"
                                id="slick-slide00"
                                aria-describedby="slick-slide-control00"
                                tabIndex={-1}
                            >
                                <div className="-link-wrapper">
                                    <picture>
                                        <img
                                            className="img-fluid -slick-item -item-10"
                                            alt="แนะนำเพื่อน"
                                            width={1200}
                                            height={590}
                                            src="/build/web/ez-bet/img/687fc8ae-ea57-47e2-8546-c3584e19a7b9.jfif"
                                        />
                                    </picture>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
                <div className="x-button-actions" id="account-actions-mobile">
                    <div className="-outer-wrapper">
                        <div className="-left-wrapper">
                            <span className="-item-wrapper">
                                <span className="-ic-img">
                                    <span className="-textfooter d-block">
                                        <a data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">เข้าสู่ระบบ</a>
                                    </span>
                                    <a data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                        <img
                                            width={150}
                                            height={150}
                                            src="/build/web/igame-index-lobby-wm/img/ic-menu-login-animate-1.png"
                                            className="image wp-image-1492  attachment-full size-full"
                                            alt=""
                                            style={{ maxWidth: "100%", height: "auto" }}
                                            decoding="async"
                                            srcSet="/build/web/igame-index-lobby-wm/img/ic-menu-login-animate-1.png 150w, /build/web/igame-index-lobby-wm/img/ic-menu-login-animate-1-24x24.png 24w, /build/web/igame-index-lobby-wm/img/ic-menu-login-animate-1-36x36.png 36w, /build/web/igame-index-lobby-wm/img/ic-menu-login-animate-1-48x48.png 48w"
                                            sizes="(max-width: 150px) 100vw, 150px"
                                        />
                                    </a>
                                </span>
                            </span>
                            <span className="-item-wrapper">
                                <span className="-ic-img">
                                    <span className="-textfooter d-block">
                                        <a href="/register">สมัคร</a>
                                    </span>
                                    <a href="/register">
                                        <img
                                            width={50}
                                            height={50}
                                            src="/build/web/igame-index-lobby-wm/img/ic-menu-register.png"
                                            className="image wp-image-1493  attachment-full size-full"
                                            alt=""
                                            style={{ maxWidth: "100%", height: "auto" }}
                                            decoding="async"
                                            srcSet="/build/web/igame-index-lobby-wm/img/ic-menu-register.png 50w, /build/web/igame-index-lobby-wm/img/ic-menu-register-24x24.png 24w, /build/web/igame-index-lobby-wm/img/ic-menu-register-36x36.png 36w, /build/web/igame-index-lobby-wm/img/ic-menu-register-48x48.png 48w"
                                            sizes="(max-width: 50px) 100vw, 50px"
                                        />
                                    </a>
                                </span>
                            </span>
                        </div>
                        <span className="-center-wrapper js-footer-lobby-selector js-menu-mobile-container">
                            <div className="-selected">
                                <h2 className="widgettitle">
                                    <a href="/">สมัครเลย</a>
                                </h2>
                                <a href="/">
                                    <img
                                        width={140}
                                        height={140}
                                        src="/build/web/ez-bet/img/logohon.png?v=1"
                                        className="image wp-image-1930  attachment-full size-full"
                                        alt=""
                                        style={{ maxWidth: "100%", height: "auto" }}
                                        decoding="async"
                                        srcSet="/build/web/ez-bet/img/logohon.png?v=1 140w, /build/web/ez-bet/img/logohon.png?v=1 24w, /build/web/ez-bet/img/logohon.png?v=1 36w, /build/web/ez-bet/img/logohon.png?v=1 48w"
                                        sizes="(max-width: 140px) 100vw, 140px"
                                    />
                                </a>
                            </div>
                        </span>
                        <div className="-fake-center-bg-wrapper">
                            <svg viewBox="-10 -1 30 12">
                                <defs>
                                    <linearGradient
                                        id="rectangleGradient"
                                        x1="0%"
                                        y1="0%"
                                        x2="0%"
                                        y2="100%"
                                    >
                                        <stop offset="0%" stopColor="#347cf7" />
                                        <stop offset="100%" stopColor="#1321a4" />
                                    </linearGradient>
                                </defs>
                                <path d="M-10 -1 H30 V12 H-10z M 5 5 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0z" />
                            </svg>
                        </div>
                        <div className="-right-wrapper">
                            <span className="-item-wrapper">
                                <span className="-ic-img">
                                    <span className="-textfooter d-block">
                                        <a href="/promotions">โปรโมชั่น</a>
                                    </span>
                                    <a href="/promotions">
                                        <img
                                            width={258}
                                            height={257}
                                            src="/build/web/igame-index-lobby-wm/img/tab_promotion-1.png"
                                            className="image wp-image-1495  attachment-full size-full"
                                            alt=""
                                            style={{ maxWidth: "100%", height: "auto" }}
                                            decoding="async"
                                            srcSet="/build/web/igame-index-lobby-wm/img/tab_promotion-1.png 258w, /build/web/igame-index-lobby-wm/img/tab_promotion-1-150x150.png 150w, /build/web/igame-index-lobby-wm/img/tab_promotion-1-24x24.png 24w, /build/web/igame-index-lobby-wm/img/tab_promotion-1-36x36.png 36w, /build/web/igame-index-lobby-wm/img/tab_promotion-1-48x48.png 48w"
                                            sizes="(max-width: 258px) 100vw, 258px"
                                        />
                                    </a>
                                </span>
                            </span>
                            <span className="-item-wrapper">
                                <span className="-ic-img">
                                    <span className="-textfooter d-block">
                                        <a href="#">ติดต่อเรา</a>
                                    </span>
                                    <a href={this.state.linelink}>
                                        <img
                                            width={123}
                                            height={122}
                                            src="/build/web/igame-index-lobby-wm/img/support.png"
                                            className="image wp-image-1494  attachment-full size-full"
                                            alt=""
                                            style={{ maxWidth: "100%", height: "auto" }}
                                            decoding="async"
                                            srcSet="/build/web/igame-index-lobby-wm/img/support.png 123w, /build/web/igame-index-lobby-wm/img/support-24x24.png 24w, /build/web/igame-index-lobby-wm/img/support-36x36.png 36w, /build/web/igame-index-lobby-wm/img/support-48x48.png 48w"
                                            sizes="(max-width: 123px) 100vw, 123px"
                                        />
                                    </a>
                                </span>
                            </span>
                        </div>
                        <div className="-fully-overlay js-footer-lobby-overlay" />
                    </div>
                </div>
                <div className="containmain">
                    <div className="fixedcontain">
                        <div className="fixedleft ">
                            <div className="sidegamebar ">
                                <div className="tabgamemenu">
                                    <div className="menu-menu-left-container">
                                        <ul id="menu-menu-left" className="menu">
                                            <li
                                                id="menu-item-402"
                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-402"
                                            >
                                                <a
                                                    href="/sport"
                                                    className="menu-image-title-after menu-image-not-hovered"
                                                >
                                                    <img
                                                        width={300}
                                                        height={300}
                                                        src="/build/web/igame-index-lobby-wm/img/Artboard-5-300x300-1.png"
                                                        className="menu-image menu-image-title-after"
                                                        alt=""
                                                        decoding="async"
                                                    />
                                                    <span className="menu-image-title-after menu-image-title">
                                                        กีฬา
                                                    </span>
                                                </a>
                                            </li>
                                            <li
                                                id="menu-item-1102"
                                                className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-1101"
                                            >
                                                <a
                                                    href="/slot"
                                                    aria-current="page"
                                                    className="menu-image-title-after menu-image-not-hovered"
                                                >
                                                    <img
                                                        width={300}
                                                        height={300}
                                                        src="/build/web/igame-index-lobby-wm/img/slot-1.png"
                                                        className="menu-image menu-image-title-after"
                                                        alt=""
                                                        decoding="async"
                                                    />
                                                    <span className="menu-image-title-after menu-image-title">
                                                        สล็อต
                                                    </span>
                                                </a>
                                            </li>
                                            <li
                                                id="menu-item-1108"
                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1108"
                                            >
                                                <a
                                                    href="/casino"
                                                    className="menu-image-title-after menu-image-not-hovered"
                                                >
                                                    <img
                                                        width={300}
                                                        height={300}
                                                        src="/build/web/igame-index-lobby-wm/img/casino.png"
                                                        className="menu-image menu-image-title-after"
                                                        alt=""
                                                        decoding="async"
                                                    />
                                                    <span className="menu-image-title-after menu-image-title">
                                                        คาสิโน
                                                    </span>
                                                </a>
                                            </li>
                                            <li
                                                id="menu-item-1108"
                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1108"
                                            >
                                                <a
                                                    href="/slot"
                                                    className="menu-image-title-after menu-image-not-hovered"
                                                >
                                                    <img
                                                        width={300}
                                                        height={300}
                                                        src="https://cdn.javisauto.live/image/54a23cf3-d987-46b2-8e06-ca9c6fa7c4e4.png"
                                                        className="menu-image menu-image-title-after"
                                                        alt=""
                                                        decoding="async"
                                                    />
                                                    <span className="menu-image-title-after menu-image-title">
                                                        ยิงปลา
                                                    </span>
                                                </a>
                                            </li>
                                            <li
                                                id="menu-item-1808"
                                                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1808"
                                            >
                                                <a
                                                    href="/promotions"
                                                    className="menu-image-title-after menu-image-not-hovered"
                                                >
                                                    <img
                                                        width={300}
                                                        height={300}
                                                        src="/build/web/igame-index-lobby-wm/img/pro.png"
                                                        className="menu-image menu-image-title-after"
                                                        alt=""
                                                        decoding="async"
                                                    />
                                                    <span className="menu-image-title-after menu-image-title">
                                                        โปรโมชั่น
                                                    </span>
                                                </a>
                                            </li>
                                            <li
                                                id="menu-item-1809"
                                                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1809"
                                            >
                                                <a
                                                    href={this.state.linelink}
                                                    className="menu-image-title-after menu-image-not-hovered"
                                                >
                                                    <img
                                                        width={300}
                                                        height={300}
                                                        src="/build/web/igame-index-lobby-wm/img/call.png"
                                                        className="menu-image menu-image-title-after"
                                                        alt=""
                                                        decoding="async"
                                                    />
                                                    <span className="menu-image-title-after menu-image-title">
                                                        ติดต่อ
                                                    </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="linechatfixed">
                                    <a href={this.state.linelink}>
                                        <img
                                            width={202}
                                            height={64}
                                            src="/build/web/igame-index-lobby-wm/img/ic-line-text.png"
                                            className="image wp-image-1797  attachment-full size-full"
                                            alt=""
                                            style={{ maxWidth: "100%", height: "auto" }}
                                            decoding="async"
                                            srcSet="/build/web/igame-index-lobby-wm/img/ic-line-text.png 202w, /build/web/igame-index-lobby-wm/img/ic-line-text-24x8.png 24w, /build/web/igame-index-lobby-wm/img/ic-line-text-36x11.png 36w, /build/web/igame-index-lobby-wm/img/ic-line-text-48x15.png 48w"
                                            sizes="(max-width: 202px) 100vw, 202px"
                                        />
                                    </a>
                                </div>{" "}

                            </div>
                        </div>
                        <div className="fixedright">
                            <div className="sectiongame" style={{ display: typepage !== "promotions" && typepage !== "register" && typepage !== "main" && typepage !== "slot" && typepage !== "sport" && typepage !== "casino" ? "none" : "" }}>
                                <div className="containmain">
                                    <div
                                        data-elementor-type="wp-page"
                                        data-elementor-id={1101}
                                        className="elementor elementor-1101"
                                    >
                                        <section
                                            className="elementor-section elementor-top-section elementor-element elementor-element-d4cadb5 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="4a0dc07"

                                            data-element_type="section"
                                            data-settings='{"background_background":"classic"}'
                                        >
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a8aa458"
                                                    // style={{backgroundImage:"url(/build/web/igame-index-lobby-wm/img/3661943.jpg)",backgroundPosition:"center center",backgroundSize:"cover"}}
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <section
                                                            className="elementor-section elementor-inner-section elementor-element elementor-element-1e1fe9f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                            data-id="1e1fe9f"
                                                            data-element_type="section"
                                                            data-settings='{"background_background":"classic"}'
                                                        >
                                                            <div className="elementor-container elementor-column-gap-default">
                                                                <div
                                                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-ca5440f"
                                                                    data-id="ca5440f"
                                                                    data-element_type="column"
                                                                    data-settings='{"background_background":"classic"}'
                                                                >
                                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                                        <div className="elementor-background-overlay" />
                                                                        <div
                                                                            className="elementor-element elementor-element-f9d3e45 elementor-widget elementor-widget-site-logo"
                                                                            data-id="f9d3e45"
                                                                            data-element_type="widget"
                                                                            data-settings='{"width":{"unit":"px","size":188,"sizes":[]},"width_tablet":{"unit":"px","size":"","sizes":[]},"width_mobile":{"unit":"px","size":"","sizes":[]},"align":"center","space":{"unit":"%","size":"","sizes":[]},"space_tablet":{"unit":"%","size":"","sizes":[]},"space_mobile":{"unit":"%","size":"","sizes":[]},"image_border_radius":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_space":{"unit":"px","size":0,"sizes":[]},"caption_space_tablet":{"unit":"px","size":"","sizes":[]},"caption_space_mobile":{"unit":"px","size":"","sizes":[]}}'
                                                                            data-widget_type="site-logo.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <div className="hfe-site-logo">
                                                                                    <a
                                                                                        data-elementor-open-lightbox=""
                                                                                        className="elementor-clickable"
                                                                                        href="#"
                                                                                    >
                                                                                        <div className="hfe-site-logo-set">
                                                                                            <div className="hfe-site-logo-container">
                                                                                                <img
                                                                                                    decoding="async"
                                                                                                    className="hfe-site-logo-img elementor-animation-"
                                                                                                    src="/build/web/ez-bet/img/logo-black.png?v=1"
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="elementor-element elementor-element-7f3ac69 elementor-widget elementor-widget-heading"
                                                                            data-id="7f3ac69"
                                                                            data-element_type="widget"
                                                                            data-widget_type="heading.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <h1 className="elementor-heading-title elementor-size-default">
                                                                                    ยินดีต้อนรับเข้าสู่ Trixie168
                                                                                </h1>{" "}
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="elementor-element elementor-element-58150fb elementor-widget elementor-widget-text-editor"
                                                                            data-id="58150fb"
                                                                            data-element_type="widget"
                                                                            data-widget_type="text-editor.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <p>
                                                                                    Trixie168
                                                                                    สนุกกับสล็อตเกมแตกง่ยบนค่ายเกมคาสิโนออนไลน์ชั้นนำระดับประเทศในปี
                                                                                    2022
                                                                                </p>
                                                                                <p>
                                                                                    บนค่ายดังยอดฮิตตลอดการอย่าง Evoplay, Joker, PG
                                                                                    Slot และอื่นๆ อีกมากมาย
                                                                                </p>{" "}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <section
                                                            className="elementor-section elementor-inner-section elementor-element elementor-element-ca75c7d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                            data-id="ca75c7d"
                                                            data-element_type="section"
                                                            data-settings='{"background_background":"classic"}'
                                                        >
                                                            <div className="elementor-container elementor-column-gap-default">
                                                                <div
                                                                    className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-5aaed39"
                                                                    data-id="5aaed39"
                                                                    data-element_type="column"
                                                                >
                                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                                        <div
                                                                            className="elementor-element elementor-element-719b85c elementor-widget elementor-widget-image"
                                                                            data-id="719b85c"
                                                                            data-element_type="widget"
                                                                            data-widget_type="image.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <img
                                                                                    loading="lazy"
                                                                                    decoding="async"
                                                                                    width={698}
                                                                                    height={698}
                                                                                    src="/build/web/igame-index-lobby-wm/img/wm-casino-info-banner-1-2.png"
                                                                                    className="attachment-large size-large"
                                                                                    alt=""
                                                                                    srcSet="/build/web/igame-index-lobby-wm/img/wm-casino-info-banner-1-2.png 698w, /build/web/igame-index-lobby-wm/img/wm-casino-info-banner-1-2-300x300.png 300w, /build/web/igame-index-lobby-wm/img/wm-casino-info-banner-1-2-150x150.png 150w, /build/web/igame-index-lobby-wm/img/wm-casino-info-banner-1-2-24x24.png 24w, /build/web/igame-index-lobby-wm/img/wm-casino-info-banner-1-2-36x36.png 36w, /build/web/igame-index-lobby-wm/img/wm-casino-info-banner-1-2-48x48.png 48w"
                                                                                    sizes="(max-width: 698px) 100vw, 698px"
                                                                                />{" "}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-a5e34d1"
                                                                    data-id="a5e34d1"
                                                                    data-element_type="column"
                                                                    data-settings='{"background_background":"gradient"}'
                                                                >
                                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                                        <div
                                                                            className="elementor-element elementor-element-bd4e2ed elementor-aspect-ratio-169 elementor-widget elementor-widget-video"
                                                                            data-id="bd4e2ed"
                                                                            data-element_type="widget"
                                                                            data-settings='{"autoplay":"yes","play_on_mobile":"yes","mute":"yes","loop":"yes","video_type":"hosted","aspect_ratio":"169"}'
                                                                            data-widget_type="video.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <div className="e-hosted-video elementor-wrapper elementor-fit-aspect-ratio elementor-open-inline">
                                                                                    <video
                                                                                        className="elementor-video"
                                                                                        src="/build/web/igame-index-lobby-wm/img/file.mp4"
                                                                                        autoPlay="true"
                                                                                        loop="true"
                                                                                        muted="muted"
                                                                                        playsInline=""
                                                                                        controlslist="nodownload"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section className="elementor-section elementor-top-section elementor-element elementor-element-75bf254 linesection01 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="75bf254"
                                            data-element_type="section"
                                            data-settings='{"background_background":"slideshow","background_slideshow_gallery":[],"background_slideshow_loop":"yes","background_slideshow_slide_duration":5000,"background_slideshow_slide_transition":"fade","background_slideshow_transition_duration":500}'
                                        >
                                            <div
                                                className="elementor-background-slideshow swiper-container"
                                                dir="rtl"
                                            >
                                                <div className="swiper-wrapper" />
                                            </div>
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-97fb5ba"
                                                    data-id="97fb5ba"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">

                                                        <section style={{ display: typepage === "slot" || typepage === "main" ? "" : "none" }} className="elementor-section elementor-inner-section elementor-element elementor-element-c371a27 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                            data-id="c371a27"
                                                            data-element_type="section"
                                                            data-settings='{"background_background":"gradient"}'
                                                        >
                                                            <div className="elementor-container elementor-column-gap-default">
                                                                <div
                                                                    className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-8ff075d"
                                                                    data-id="8ff075d"
                                                                    data-element_type="column"
                                                                >
                                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                                        <div
                                                                            className="elementor-element elementor-element-acc9cfc elementor-widget elementor-widget-image"
                                                                            data-id="acc9cfc"
                                                                            data-element_type="widget"
                                                                            data-widget_type="image.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <img
                                                                                    loading="lazy"
                                                                                    decoding="async"
                                                                                    width={300}
                                                                                    height={300}
                                                                                    src="/build/web/igame-index-lobby-wm/img/slot-1.png"
                                                                                    className="attachment-large size-large"
                                                                                    alt=""
                                                                                    srcSet="/build/web/igame-index-lobby-wm/img/slot-1.png 300w, /build/web/igame-index-lobby-wm/img/slot-1-150x150.png 150w, /build/web/igame-index-lobby-wm/img/slot-1-24x24.png 24w, /build/web/igame-index-lobby-wm/img/slot-1-36x36.png 36w, /build/web/igame-index-lobby-wm/img/slot-1-48x48.png 48w"
                                                                                    sizes="(max-width: 300px) 100vw, 300px"
                                                                                />{" "}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-930ec96"
                                                                    data-id="930ec96"
                                                                    data-element_type="column"
                                                                >
                                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                                        <div
                                                                            className="elementor-element elementor-element-8f50c56 elementor-widget elementor-widget-heading"
                                                                            data-id="8f50c56"
                                                                            data-element_type="widget"
                                                                            data-widget_type="heading.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <h2 className="elementor-heading-title elementor-size-default">
                                                                                    ค่ายเกมสล็อต
                                                                                </h2>{" "}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <div style={{ display: typepage === "slot" || typepage === "main" ? "" : "none" }}

                                                            className="elementor-element elementor-element-9992270 uael-img-grid-tablet__column-4 uael-img-grid__column-4 uael-img-grid-mobile__column-2 uael-ins-normal elementor-widget elementor-widget-uael-image-gallery"
                                                            data-id={9992270}
                                                            data-element_type="widget"
                                                            data-settings='{"column_gap":{"unit":"px","size":15,"sizes":[]},"row_gap":{"unit":"px","size":15,"sizes":[]},"gallery_columns_tablet":"4","column_gap_mobile":{"unit":"px","size":5,"sizes":[]},"row_gap_mobile":{"unit":"px","size":5,"sizes":[]},"gallery_columns":"4","gallery_columns_mobile":"2","column_gap_tablet":{"unit":"px","size":"","sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]},"images_valign":"flex-start"}'
                                                            data-widget_type="uael-image-gallery.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="uael-gallery-parent uael-caption- uael-gallery-unjustified">
                                                                    <div
                                                                        className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                                        data-filter-default="All"
                                                                    >
                                                                        <div className="uael-grid-item  uael-img-gallery-item-1">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img


                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/1-joker.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>


                                                                        <div className="uael-grid-item  uael-img-gallery-item-2">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/2-spadegaming.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-3">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/3-pg.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-4">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/4-evoplay.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-5">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/5-pragmatic-play.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-6">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/6-dreamtech.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-7">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/7-jili.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-8">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/8-You-Lian-slot.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-9">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/9-fachai.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-10">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/10-kingmaker.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-11">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/11-red-tiger.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-12">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/12-manna-play.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-13">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/13-funta-gaming.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-14">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/14-ambslot.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-15">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/15-microgame.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-16">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/16-funkygame.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-17">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/17-keno-numbergame.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-18">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/18-Booongo.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-19">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/19-allwayspin.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-20">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/20-gamatron.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-21">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/21-biggame.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-22">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/22-atom.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-23">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/23-iconic-gaming.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-24">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/24-jili.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-25">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/25-keno.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-26">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/26-simpleplay.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-27">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={262}
                                                                                            height={171}
                                                                                            src="/build/web/igame-index-lobby-wm/img/27-ka-gaming.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>{" "}
                                                            </div>
                                                        </div>
                                                        <div style={{ display: typepage === "slot" || typepage === "main" ? "" : "none" }}
                                                            className="elementor-element elementor-element-2fef66f uael-img-grid__column-4 uael-img-grid-tablet__column-3 uael-img-grid-mobile__column-2 uael-ins-normal elementor-widget elementor-widget-uael-image-gallery"
                                                            data-id="2fef66f"
                                                            data-element_type="widget"
                                                            data-settings='{"gallery_columns":"4","gallery_columns_tablet":"3","gallery_columns_mobile":"2","column_gap":{"unit":"px","size":20,"sizes":[]},"column_gap_tablet":{"unit":"px","size":"","sizes":[]},"column_gap_mobile":{"unit":"px","size":"","sizes":[]},"row_gap":{"unit":"px","size":20,"sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]},"row_gap_mobile":{"unit":"px","size":"","sizes":[]},"images_valign":"flex-start"}'
                                                            data-widget_type="uael-image-gallery.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="uael-gallery-parent uael-caption- uael-gallery-unjustified">
                                                                    <div
                                                                        className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                                        data-filter-default="All"
                                                                    />
                                                                </div>{" "}
                                                            </div>
                                                        </div>
                                                        <section style={{ display: typepage === "sport" || typepage === "main" ? "" : "none" }} className="elementor-section elementor-inner-section elementor-element elementor-element-2ee508e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                            data-id="2ee508e"
                                                            data-element_type="section"
                                                            data-settings='{"background_background":"gradient"}'
                                                        >
                                                            <div className="elementor-container elementor-column-gap-default">
                                                                <div
                                                                    className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f7c2c7e"
                                                                    data-id="f7c2c7e"
                                                                    data-element_type="column"
                                                                >
                                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                                        <div
                                                                            className="elementor-element elementor-element-9c14788 elementor-widget elementor-widget-image"
                                                                            data-id="9c14788"
                                                                            data-element_type="widget"
                                                                            data-widget_type="image.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <img
                                                                                    loading="lazy"
                                                                                    decoding="async"
                                                                                    width={300}
                                                                                    height={300}
                                                                                    src="/build/web/igame-index-lobby-wm/img/Artboard-5-300x300-1.png"
                                                                                    className="attachment-large size-large"
                                                                                    alt=""
                                                                                    srcSet="/build/web/igame-index-lobby-wm/img/Artboard-5-300x300-1.png 300w, /build/web/igame-index-lobby-wm/img/Artboard-5-300x300-1-150x150.png 150w, /build/web/igame-index-lobby-wm/img/Artboard-5-300x300-1-24x24.png 24w, /build/web/igame-index-lobby-wm/img/Artboard-5-300x300-1-36x36.png 36w, /build/web/igame-index-lobby-wm/img/Artboard-5-300x300-1-48x48.png 48w"
                                                                                    sizes="(max-width: 300px) 100vw, 300px"
                                                                                />{" "}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-eaea5d0"
                                                                    data-id="eaea5d0"
                                                                    data-element_type="column"
                                                                >
                                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                                        <div
                                                                            className="elementor-element elementor-element-32676a8 elementor-widget elementor-widget-heading"
                                                                            data-id="32676a8"
                                                                            data-element_type="widget"
                                                                            data-widget_type="heading.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <h2 className="elementor-heading-title elementor-size-default">
                                                                                    ค่ายเกมกีฬา
                                                                                </h2>{" "}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <div style={{ display: typepage === "sport" || typepage === "main" ? "" : "none" }} className="elementor-element elementor-element-49e5652 uael-img-grid__column-2 uael-img-grid-tablet__column-2 uael-img-grid-mobile__column-1 uael-ins-normal elementor-widget elementor-widget-uael-image-gallery"
                                                            data-id="49e5652"
                                                            data-element_type="widget"
                                                            data-settings='{"gallery_columns":"2","gallery_columns_tablet":"2","gallery_columns_mobile":"1","column_gap":{"unit":"px","size":20,"sizes":[]},"column_gap_tablet":{"unit":"px","size":"","sizes":[]},"column_gap_mobile":{"unit":"px","size":"","sizes":[]},"row_gap":{"unit":"px","size":20,"sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]},"row_gap_mobile":{"unit":"px","size":"","sizes":[]},"images_valign":"flex-start"}'
                                                            data-widget_type="uael-image-gallery.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="uael-gallery-parent uael-caption- uael-gallery-unjustified">
                                                                    <div
                                                                        className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                                        data-filter-default="All"
                                                                    >
                                                                        <div className="uael-grid-item  uael-img-gallery-item-1">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={527}
                                                                                            height={224}
                                                                                            src="/build/web/igame-index-lobby-wm/img/1-sbobet.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-2">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={527}
                                                                                            height={224}
                                                                                            src="/build/web/igame-index-lobby-wm/img/2-3singsport.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>{" "}
                                                            </div>
                                                        </div>

                                                        <section style={{ display: typepage === "casino" || typepage === "main" ? "" : "none" }}
                                                            className="elementor-section elementor-inner-section elementor-element elementor-element-562b4a5 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                            data-id="562b4a5"
                                                            data-element_type="section"
                                                            data-settings='{"background_background":"gradient"}'
                                                        >
                                                            <div className="elementor-container elementor-column-gap-default">
                                                                <div
                                                                    className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-fccbedc"
                                                                    data-id="fccbedc"
                                                                    data-element_type="column"
                                                                >
                                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                                        <div
                                                                            className="elementor-element elementor-element-eab7ce8 elementor-widget elementor-widget-image"
                                                                            data-id="eab7ce8"
                                                                            data-element_type="widget"
                                                                            data-widget_type="image.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <img
                                                                                    loading="lazy"
                                                                                    decoding="async"
                                                                                    width={300}
                                                                                    height={300}
                                                                                    src="/build/web/igame-index-lobby-wm/img/casino.png"
                                                                                    className="attachment-large size-large"
                                                                                    alt=""
                                                                                    srcSet="/build/web/igame-index-lobby-wm/img/casino.png 300w, /build/web/igame-index-lobby-wm/img/casino-150x150.png 150w, /build/web/igame-index-lobby-wm/img/casino-24x24.png 24w, /build/web/igame-index-lobby-wm/img/casino-36x36.png 36w, /build/web/igame-index-lobby-wm/img/casino-48x48.png 48w"
                                                                                    sizes="(max-width: 300px) 100vw, 300px"
                                                                                />{" "}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-1329700"
                                                                    data-id={1329700}

                                                                    data-element_type="column" >
                                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                                        <div
                                                                            className="elementor-element elementor-element-e110183 elementor-widget elementor-widget-heading"
                                                                            data-id="e110183"
                                                                            data-element_type="widget"
                                                                            data-widget_type="heading.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <h2 className="elementor-heading-title elementor-size-default">
                                                                                    ค่ายเกมคาสิโน
                                                                                </h2>{" "}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <section style={{ display: typepage === "casino" || typepage === "main" ? "" : "none" }}
                                                            className="elementor-section elementor-inner-section elementor-element elementor-element-0de6d7f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                            data-id="0de6d7f"
                                                            data-element_type="section"
                                                        >
                                                            <div className="elementor-container elementor-column-gap-default">
                                                                <div
                                                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-d09f127"
                                                                    data-id="d09f127"
                                                                    data-element_type="column"
                                                                >
                                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                                        <div
                                                                            className="elementor-element elementor-element-19a0e78 uael-img-grid-tablet__column-4 uael-img-grid__column-4 uael-img-grid-mobile__column-2 uael-ins-normal elementor-widget elementor-widget-uael-image-gallery"
                                                                            data-id="19a0e78"
                                                                            data-element_type="widget"
                                                                            data-settings='{"gallery_columns_tablet":"4","column_gap":{"unit":"px","size":15,"sizes":[]},"column_gap_mobile":{"unit":"px","size":5,"sizes":[]},"row_gap":{"unit":"px","size":15,"sizes":[]},"row_gap_mobile":{"unit":"px","size":5,"sizes":[]},"gallery_columns":"4","gallery_columns_mobile":"2","column_gap_tablet":{"unit":"px","size":"","sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]},"images_valign":"flex-start"}'
                                                                            data-widget_type="uael-image-gallery.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <div className="uael-gallery-parent uael-caption- uael-gallery-unjustified">
                                                                                    <div
                                                                                        className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                                                        data-filter-default="All"
                                                                                    >
                                                                                        <div className="uael-grid-item  uael-img-gallery-item-1">
                                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                                        <img
                                                                                                            loading="lazy"
                                                                                                            decoding="async"
                                                                                                            width={262}
                                                                                                            height={171}
                                                                                                            src="/build/web/igame-index-lobby-wm/img/1-allbet.png"
                                                                                                            className="attachment-full size-full"
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="uael-grid-img-overlay" />
                                                                                                </figure>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="uael-grid-item  uael-img-gallery-item-2">
                                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                                        <img
                                                                                                            loading="lazy"
                                                                                                            decoding="async"
                                                                                                            width={262}
                                                                                                            height={171}
                                                                                                            src="/build/web/igame-index-lobby-wm/img/2-wmcasino.png"
                                                                                                            className="attachment-full size-full"
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="uael-grid-img-overlay" />
                                                                                                </figure>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="uael-grid-item  uael-img-gallery-item-3">
                                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                                        <img
                                                                                                            loading="lazy"
                                                                                                            decoding="async"
                                                                                                            width={262}
                                                                                                            height={171}
                                                                                                            src="/build/web/igame-index-lobby-wm/img/3-pragmatic-play.png"
                                                                                                            className="attachment-full size-full"
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="uael-grid-img-overlay" />
                                                                                                </figure>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="uael-grid-item  uael-img-gallery-item-4">
                                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                                        <img
                                                                                                            loading="lazy"
                                                                                                            decoding="async"
                                                                                                            width={262}
                                                                                                            height={171}
                                                                                                            src="/build/web/igame-index-lobby-wm/img/4-aesexy.png"
                                                                                                            className="attachment-full size-full"
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="uael-grid-img-overlay" />
                                                                                                </figure>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="uael-grid-item  uael-img-gallery-item-5">
                                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                                        <img
                                                                                                            loading="lazy"
                                                                                                            decoding="async"
                                                                                                            width={262}
                                                                                                            height={171}
                                                                                                            src="/build/web/igame-index-lobby-wm/img/5-sa-gaming.png"
                                                                                                            className="attachment-full size-full"
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="uael-grid-img-overlay" />
                                                                                                </figure>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="uael-grid-item  uael-img-gallery-item-6">
                                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                                        <img
                                                                                                            loading="lazy"
                                                                                                            decoding="async"
                                                                                                            width={262}
                                                                                                            height={171}
                                                                                                            src="/build/web/igame-index-lobby-wm/img/6-pretty-gaming.png"
                                                                                                            className="attachment-full size-full"
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="uael-grid-img-overlay" />
                                                                                                </figure>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="uael-grid-item  uael-img-gallery-item-7">
                                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                                        <img
                                                                                                            loading="lazy"
                                                                                                            decoding="async"
                                                                                                            width={262}
                                                                                                            height={171}
                                                                                                            src="/build/web/igame-index-lobby-wm/img/7-ebet.png"
                                                                                                            className="attachment-full size-full"
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="uael-grid-img-overlay" />
                                                                                                </figure>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="uael-grid-item  uael-img-gallery-item-8">
                                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                                        <img
                                                                                                            loading="lazy"
                                                                                                            decoding="async"
                                                                                                            width={262}
                                                                                                            height={171}
                                                                                                            src="/build/web/igame-index-lobby-wm/img/8-venuscasino.png"
                                                                                                            className="attachment-full size-full"
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="uael-grid-img-overlay" />
                                                                                                </figure>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>{" "}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <section style={{ display: typepage === "casino" || typepage === "main" ? "" : "none" }}
                                                            className="elementor-section elementor-inner-section elementor-element elementor-element-b9d070e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                            data-id="b9d070e"
                                                            data-element_type="section"
                                                        >
                                                            <div className="elementor-container elementor-column-gap-default">
                                                                <div
                                                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-59963bf"
                                                                    data-id="59963bf"
                                                                    data-element_type="column"
                                                                >
                                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                                        <div
                                                                            className="elementor-element elementor-element-a3dfe88 uael-img-grid__column-3 uael-img-grid-tablet__column-3 uael-img-grid-mobile__column-2 uael-ins-normal elementor-widget elementor-widget-uael-image-gallery"
                                                                            data-id="a3dfe88"
                                                                            data-element_type="widget"
                                                                            data-settings='{"gallery_columns":"3","column_gap":{"unit":"px","size":15,"sizes":[]},"row_gap":{"unit":"px","size":15,"sizes":[]},"gallery_columns_tablet":"3","gallery_columns_mobile":"2","column_gap_tablet":{"unit":"px","size":"","sizes":[]},"column_gap_mobile":{"unit":"px","size":"","sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]},"row_gap_mobile":{"unit":"px","size":"","sizes":[]},"images_valign":"flex-start"}'
                                                                            data-widget_type="uael-image-gallery.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <div className="uael-gallery-parent uael-caption- uael-gallery-unjustified">
                                                                                    <div
                                                                                        className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                                                        data-filter-default="All"
                                                                                    >
                                                                                        <div className="uael-grid-item  uael-img-gallery-item-1">
                                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                                        <img
                                                                                                            loading="lazy"
                                                                                                            decoding="async"
                                                                                                            width={262}
                                                                                                            height={171}
                                                                                                            src="/build/web/igame-index-lobby-wm/img/9-dreamgaming.png"
                                                                                                            className="attachment-full size-full"
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="uael-grid-img-overlay" />
                                                                                                </figure>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="uael-grid-item  uael-img-gallery-item-2">
                                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                                        <img
                                                                                                            loading="lazy"
                                                                                                            decoding="async"
                                                                                                            width={262}
                                                                                                            height={171}
                                                                                                            src="/build/web/igame-index-lobby-wm/img/10-d88-dragon.png"
                                                                                                            className="attachment-full size-full"
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="uael-grid-img-overlay" />
                                                                                                </figure>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="uael-grid-item  uael-img-gallery-item-3">
                                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                                        <img
                                                                                                            loading="lazy"
                                                                                                            decoding="async"
                                                                                                            width={262}
                                                                                                            height={171}
                                                                                                            src="/build/web/igame-index-lobby-wm/img/11-betgames-tv.png"
                                                                                                            className="attachment-full size-full"
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="uael-grid-img-overlay" />
                                                                                                </figure>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>{" "}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <section style={{ display: typepage === "casino" || typepage === "main" ? "" : "none" }}
                                                            className="elementor-section elementor-inner-section elementor-element elementor-element-c997540 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                            data-id="c997540"
                                                            data-element_type="section"
                                                            data-settings='{"background_background":"gradient"}'
                                                        >
                                                            <div className="elementor-container elementor-column-gap-default">
                                                                <div
                                                                    className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-9034bbd"
                                                                    data-id="9034bbd"
                                                                    data-element_type="column"
                                                                >
                                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                                        <div
                                                                            className="elementor-element elementor-element-66b9fe7 elementor-widget elementor-widget-image"
                                                                            data-id="66b9fe7"
                                                                            data-element_type="widget"
                                                                            data-widget_type="image.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <img
                                                                                    loading="lazy"
                                                                                    decoding="async"
                                                                                    width={300}
                                                                                    height={300}
                                                                                    src="/build/web/igame-index-lobby-wm/img/slot-1.png"
                                                                                    className="attachment-large size-large"
                                                                                    alt=""
                                                                                    srcSet="/build/web/igame-index-lobby-wm/img/slot-1.png 300w, /build/web/igame-index-lobby-wm/img/slot-1-150x150.png 150w, /build/web/igame-index-lobby-wm/img/slot-1-24x24.png 24w, /build/web/igame-index-lobby-wm/img/slot-1-36x36.png 36w, /build/web/igame-index-lobby-wm/img/slot-1-48x48.png 48w"
                                                                                    sizes="(max-width: 300px) 100vw, 300px"
                                                                                />{" "}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-93050ec"
                                                                    data-id="93050ec"
                                                                    data-element_type="column"
                                                                >
                                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                                        <div
                                                                            className="elementor-element elementor-element-793e351 elementor-widget elementor-widget-heading"
                                                                            data-id="793e351"
                                                                            data-element_type="widget"
                                                                            data-widget_type="heading.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <h2 className="elementor-heading-title elementor-size-default">
                                                                                    ค่ายเกมสล็อต
                                                                                </h2>{" "}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <div style={{ display: typepage === "casino" || typepage === "main" ? "" : "none" }}
                                                            className="elementor-element elementor-element-5f63736 uael-img-grid-tablet__column-4 uael-img-grid__column-4 uael-img-grid-mobile__column-2 uael-ins-normal elementor-widget elementor-widget-uael-image-gallery"
                                                            data-id="5f63736"
                                                            data-element_type="widget"
                                                            data-settings='{"column_gap":{"unit":"px","size":15,"sizes":[]},"row_gap":{"unit":"px","size":15,"sizes":[]},"gallery_columns_tablet":"4","column_gap_mobile":{"unit":"px","size":5,"sizes":[]},"row_gap_mobile":{"unit":"px","size":5,"sizes":[]},"gallery_columns":"4","gallery_columns_mobile":"2","column_gap_tablet":{"unit":"px","size":"","sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]},"images_valign":"flex-start"}'
                                                            data-widget_type="uael-image-gallery.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="uael-gallery-parent uael-caption- uael-gallery-unjustified">
                                                                    <div
                                                                        className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                                        data-filter-default="All"
                                                                    >
                                                                        <div className="uael-grid-item  uael-img-gallery-item-1">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={285}
                                                                                            height={417}
                                                                                            src="/build/web/igame-index-lobby-wm/img/hot-game1-1.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-2">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={285}
                                                                                            height={417}
                                                                                            src="/build/web/igame-index-lobby-wm/img/hot-game2-1.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-3">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={285}
                                                                                            height={417}
                                                                                            src="/build/web/igame-index-lobby-wm/img/hot-game3-1.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-4">
                                                                            <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={285}
                                                                                            height={417}
                                                                                            src="/build/web/igame-index-lobby-wm/img/hot-game4-1.png"
                                                                                            className="attachment-full size-full"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>{" "}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div className="sectiongame" style={{ display: typepage === "promotions" ? "" : "none" }}>
                                <div className="containmain">
                                    <div
                                        data-elementor-type="wp-page"
                                        data-elementor-id={212}
                                        className="elementor elementor-212"
                                    >
                                        <section
                                            className="elementor-section elementor-top-section elementor-element elementor-element-38ed3ae elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="38ed3ae"
                                            data-element_type="section"
                                        >
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fb5eb22"
                                                    data-id="fb5eb22"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-d8b94a3 elementor-widget elementor-widget-heading"
                                                            data-id="d8b94a3"
                                                            data-element_type="widget"
                                                            data-widget_type="heading.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <h3 className="elementor-heading-title elementor-size-default">
                                                                    โปรโมชั่นสุดพิเศษ
                                                                </h3>{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section
                                            className="elementor-section elementor-top-section elementor-element elementor-element-9f0791d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="9f0791d"
                                            data-element_type="section"
                                        >
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8f603fd"
                                                    data-id="8f603fd"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-7df2b9b uael-img-grid__column-2 uael-img-grid-tablet__column-1 uael-img-grid-mobile__column-1 uael-ins-normal elementor-widget elementor-widget-uael-image-gallery"
                                                            data-id="7df2b9b"
                                                            data-element_type="widget"
                                                            data-settings='{"gallery_columns":"2","gallery_columns_tablet":"1","gallery_columns_mobile":"1","column_gap":{"unit":"px","size":20,"sizes":[]},"column_gap_tablet":{"unit":"px","size":"","sizes":[]},"column_gap_mobile":{"unit":"px","size":"","sizes":[]},"row_gap":{"unit":"px","size":20,"sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]},"row_gap_mobile":{"unit":"px","size":"","sizes":[]},"images_valign":"flex-start"}'
                                                            data-widget_type="uael-image-gallery.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="uael-gallery-parent uael-caption- uael-gallery-unjustified">
                                                                    <div
                                                                        className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                                        data-filter-default="All"
                                                                    >
                                                                        {prolistx.map(prolists => (
                                                                            <a data-target="#loginmodal" data-dismiss="modal" data-toggle="modal" >
                                                                                <div className="uael-grid-item  uael-img-gallery-item-1">

                                                                                    <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                                        <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                            <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                                <img
                                                                                                    loading="lazy"
                                                                                                    decoding="async"
                                                                                                    src={prolists.linkpicture}
                                                                                                    className="attachment-full size-full"
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                            <div className="uael-grid-img-overlay" />
                                                                                        </figure>
                                                                                    </div>

                                                                                </div>
                                                                            </a>
                                                                        ))}

                                                                    </div>
                                                                </div>{" "}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer>
                        <div
                            data-elementor-type="wp-page"
                            data-elementor-id={1198}
                            className="elementor elementor-1198"
                        >
                            <section
                                className="elementor-section elementor-top-section elementor-element elementor-element-d7c79d8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="d7c79d8"
                                data-element_type="section"
                                data-settings='{"background_background":"classic"}'
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-286549c"
                                        data-id="286549c"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-47dcc95 elementor-widget elementor-widget-site-logo"
                                                data-id="47dcc95"
                                                data-element_type="widget"
                                                data-settings='{"width":{"unit":"px","size":246,"sizes":[]},"width_tablet":{"unit":"px","size":"","sizes":[]},"width_mobile":{"unit":"px","size":"","sizes":[]},"align":"center","space":{"unit":"%","size":"","sizes":[]},"space_tablet":{"unit":"%","size":"","sizes":[]},"space_mobile":{"unit":"%","size":"","sizes":[]},"image_border_radius":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_space":{"unit":"px","size":0,"sizes":[]},"caption_space_tablet":{"unit":"px","size":"","sizes":[]},"caption_space_mobile":{"unit":"px","size":"","sizes":[]}}'
                                                data-widget_type="site-logo.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <div className="hfe-site-logo">
                                                        <a
                                                            data-elementor-open-lightbox=""
                                                            className="elementor-clickable"
                                                            href="#"
                                                        >
                                                            <div className="hfe-site-logo-set">
                                                                <div className="hfe-site-logo-container">
                                                                    <img
                                                                        decoding="async"
                                                                        className="hfe-site-logo-img elementor-animation-"
                                                                        src="/build/web/ez-bet/img/logo-black.png?v=1"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-e7d289c elementor-widget elementor-widget-heading"
                                                data-id="e7d289c"
                                                data-element_type="widget"
                                                data-widget_type="heading.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-default">
                                                        เว็บที่ให้บริการคาสิโนออนไลน์เต็มรูปแบบ ทั้งสล็อตออนไลน์
                                                        คาสิโนสด บาคาร่าสด เกมแทงปลา เกมกีฬา ฯลฯ
                                                    </h2>{" "}
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-7daf6c7 elementor-widget elementor-widget-text-editor"
                                                data-id="7daf6c7"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p
                                                        style={{
                                                            whiteSpace: "pre-wrap",
                                                            fontSize: "medium",
                                                            marginRight: 0,
                                                            marginBottom: 0,
                                                            marginLeft: 0
                                                        }}
                                                    >
                                                        โดยผู้เล่นสามารถสนุกกับเกมของเราได้ด้วยเงินเดิมพันขั้นต่ำเพียง
                                                        10 บาทเท่านั้น และสามารถทำการ
                                                    </p>
                                                    <p
                                                        style={{
                                                            whiteSpace: "pre-wrap",
                                                            fontSize: "medium",
                                                            marginRight: 0,
                                                            marginBottom: 0,
                                                            marginLeft: 0
                                                        }}
                                                    >
                                                        ฝาก-ถอนได้ตลอด 24 ชั่วโมง ด้วยระบบออโต้ ทั้งสะดวก
                                                        รวดเร็วทันใจเป็นอย่างมากเหมาะสำหรับ
                                                    </p>
                                                    <p
                                                        style={{
                                                            whiteSpace: "pre-wrap",
                                                            fontSize: "medium",
                                                            marginRight: 0,
                                                            marginBottom: 0,
                                                            marginLeft: 0
                                                        }}
                                                    >
                                                        ผู้ที่ชื่นชอบการเดิมพันในรูปแบบของเกมการพนันออนไลน์อันดับ 1
                                                        ในปี 2021
                                                    </p>{" "}
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-4742871 elementor-widget elementor-widget-heading"
                                                data-id={4742871}
                                                data-element_type="widget"
                                                data-widget_type="heading.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-default">
                                                        ขั้นตอนการสมัคร Trixie168
                                                    </h2>{" "}
                                                </div>
                                            </div>
                                            <section
                                                className="elementor-section elementor-inner-section elementor-element elementor-element-42c4e83 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                data-id="42c4e83"
                                                data-element_type="section"
                                            >
                                                <div className="elementor-container elementor-column-gap-default">
                                                    <div
                                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-df4e9e1"
                                                        data-id="df4e9e1"
                                                        data-element_type="column"
                                                        data-settings='{"background_background":"gradient"}'
                                                    >
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div
                                                                className="elementor-element elementor-element-cbf8abc elementor-widget elementor-widget-site-logo"
                                                                data-id="cbf8abc"
                                                                data-element_type="widget"
                                                                data-settings='{"width":{"unit":"px","size":174,"sizes":[]},"width_tablet":{"unit":"px","size":"","sizes":[]},"width_mobile":{"unit":"px","size":"","sizes":[]},"align":"center","space":{"unit":"%","size":"","sizes":[]},"space_tablet":{"unit":"%","size":"","sizes":[]},"space_mobile":{"unit":"%","size":"","sizes":[]},"image_border_radius":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_space":{"unit":"px","size":0,"sizes":[]},"caption_space_tablet":{"unit":"px","size":"","sizes":[]},"caption_space_mobile":{"unit":"px","size":"","sizes":[]}}'
                                                                data-widget_type="site-logo.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <div className="hfe-site-logo">
                                                                        <a
                                                                            data-elementor-open-lightbox=""
                                                                            className="elementor-clickable"
                                                                            href="#"
                                                                        >
                                                                            <div className="hfe-site-logo-set">
                                                                                <div className="hfe-site-logo-container">
                                                                                    <img
                                                                                        decoding="async"
                                                                                        className="hfe-site-logo-img elementor-animation-"
                                                                                        src="/build/web/ez-bet/img/logo-black.png?v=1"
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="elementor-element elementor-element-490e4ec elementor-widget elementor-widget-image"
                                                                data-id="490e4ec"
                                                                data-element_type="widget"
                                                                data-widget_type="image.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <img
                                                                        loading="lazy"
                                                                        decoding="async"
                                                                        width={100}
                                                                        height={100}
                                                                        src="/build/web/igame-index-lobby-wm/img/EZ-Casino-แทงฟุตบอลพนันออนไลน์-บาสเก็ตบอล-E-Sport.png"
                                                                        className="attachment-large size-large"
                                                                        alt=""
                                                                        srcSet="/build/web/igame-index-lobby-wm/img/EZ-Casino-แทงฟุตบอลพนันออนไลน์-บาสเก็ตบอล-E-Sport.png 100w, /build/web/igame-index-lobby-wm/img/EZ-Casino-แทงฟุตบอลพนันออนไลน์-บาสเก็ตบอล-E-Sport-24x24.png 24w, /build/web/igame-index-lobby-wm/img/EZ-Casino-แทงฟุตบอลพนันออนไลน์-บาสเก็ตบอล-E-Sport-36x36.png 36w, /build/web/igame-index-lobby-wm/img/EZ-Casino-แทงฟุตบอลพนันออนไลน์-บาสเก็ตบอล-E-Sport-48x48.png 48w"
                                                                        sizes="(max-width: 100px) 100vw, 100px"
                                                                    />{" "}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="elementor-element elementor-element-9e74a44 elementor-widget elementor-widget-heading"
                                                                data-id="9e74a44"
                                                                data-element_type="widget"
                                                                data-widget_type="heading.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <h2 className="elementor-heading-title elementor-size-default">
                                                                        สมัครง่าย ไม่ยุ่งยาก
                                                                    </h2>{" "}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="elementor-element elementor-element-a69022e elementor-widget elementor-widget-text-editor"
                                                                data-id="a69022e"
                                                                data-element_type="widget"
                                                                data-widget_type="text-editor.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <p>หากเกิดปัญหา</p>
                                                                    <p>มีทีมงานมืออาชีพ</p>
                                                                    <p>พร้อมดูแลคุณตลอด 24 ชั่วโมง</p>{" "}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="elementor-element elementor-element-5ae1693 elementor-widget elementor-widget-image"
                                                                data-id="5ae1693"
                                                                data-element_type="widget"
                                                                data-widget_type="image.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <img
                                                                        loading="lazy"
                                                                        decoding="async"
                                                                        width={828}
                                                                        height={124}
                                                                        src="/build/web/igame-index-lobby-wm/img/ezgif-2-c6a36a52bb.gif"
                                                                        className="attachment-large size-large"
                                                                        alt=""
                                                                    />{" "}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-d465dc7"
                                                        data-id="d465dc7"
                                                        data-element_type="column"
                                                        data-settings='{"background_background":"gradient"}'
                                                    >
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div
                                                                className="elementor-element elementor-element-93056b6 elementor-widget elementor-widget-site-logo"
                                                                data-id="93056b6"
                                                                data-element_type="widget"
                                                                data-settings='{"width":{"unit":"px","size":174,"sizes":[]},"width_tablet":{"unit":"px","size":"","sizes":[]},"width_mobile":{"unit":"px","size":"","sizes":[]},"align":"center","space":{"unit":"%","size":"","sizes":[]},"space_tablet":{"unit":"%","size":"","sizes":[]},"space_mobile":{"unit":"%","size":"","sizes":[]},"image_border_radius":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_space":{"unit":"px","size":0,"sizes":[]},"caption_space_tablet":{"unit":"px","size":"","sizes":[]},"caption_space_mobile":{"unit":"px","size":"","sizes":[]}}'
                                                                data-widget_type="site-logo.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <div className="hfe-site-logo">
                                                                        <a
                                                                            data-elementor-open-lightbox=""
                                                                            className="elementor-clickable"
                                                                            href="#"
                                                                        >
                                                                            <div className="hfe-site-logo-set">
                                                                                <div className="hfe-site-logo-container">
                                                                                    <img
                                                                                        decoding="async"
                                                                                        className="hfe-site-logo-img elementor-animation-"
                                                                                        src="/build/web/ez-bet/img/logo-black.png?v=1"
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="elementor-element elementor-element-9a35b9b elementor-widget elementor-widget-image"
                                                                data-id="9a35b9b"
                                                                data-element_type="widget"
                                                                data-widget_type="image.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <img
                                                                        loading="lazy"
                                                                        decoding="async"
                                                                        width={100}
                                                                        height={100}
                                                                        src="/build/web/igame-index-lobby-wm/img/EZ-Casino-บริการสล็อตออนไลน์-ฝาก-100-รับเครดิตฟรี-100.png"
                                                                        className="attachment-large size-large"
                                                                        alt=""
                                                                        srcSet="/build/web/igame-index-lobby-wm/img/EZ-Casino-บริการสล็อตออนไลน์-ฝาก-100-รับเครดิตฟรี-100.png 100w, /build/web/igame-index-lobby-wm/img/EZ-Casino-บริการสล็อตออนไลน์-ฝาก-100-รับเครดิตฟรี-100-24x24.png 24w, /build/web/igame-index-lobby-wm/img/EZ-Casino-บริการสล็อตออนไลน์-ฝาก-100-รับเครดิตฟรี-100-36x36.png 36w, /build/web/igame-index-lobby-wm/img/EZ-Casino-บริการสล็อตออนไลน์-ฝาก-100-รับเครดิตฟรี-100-48x48.png 48w"
                                                                        sizes="(max-width: 100px) 100vw, 100px"
                                                                    />{" "}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="elementor-element elementor-element-5afa8ea elementor-widget elementor-widget-heading"
                                                                data-id="5afa8ea"
                                                                data-element_type="widget"
                                                                data-widget_type="heading.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <h2 className="elementor-heading-title elementor-size-default">
                                                                        สมัครง่าย ไม่ยุ่งยาก
                                                                    </h2>{" "}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="elementor-element elementor-element-e3cba84 elementor-widget elementor-widget-text-editor"
                                                                data-id="e3cba84"
                                                                data-element_type="widget"
                                                                data-widget_type="text-editor.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <p>หากเกิดปัญหา</p>
                                                                    <p>มีทีมงานมืออาชีพ</p>
                                                                    <p>พร้อมดูแลคุณตลอด 24 ชั่วโมง</p>{" "}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="elementor-element elementor-element-d0a038d elementor-widget elementor-widget-image"
                                                                data-id="d0a038d"
                                                                data-element_type="widget"
                                                                data-widget_type="image.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <img
                                                                        loading="lazy"
                                                                        decoding="async"
                                                                        width={828}
                                                                        height={124}
                                                                        src="/build/web/igame-index-lobby-wm/img/ezgif-2-c6a36a52bb.gif"
                                                                        className="attachment-large size-large"
                                                                        alt=""
                                                                    />{" "}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-b2c2a62"
                                                        data-id="b2c2a62"
                                                        data-element_type="column"
                                                        data-settings='{"background_background":"gradient"}'
                                                    >
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div
                                                                className="elementor-element elementor-element-b3bb4ce elementor-widget elementor-widget-site-logo"
                                                                data-id="b3bb4ce"
                                                                data-element_type="widget"
                                                                data-settings='{"width":{"unit":"px","size":174,"sizes":[]},"width_tablet":{"unit":"px","size":"","sizes":[]},"width_mobile":{"unit":"px","size":"","sizes":[]},"align":"center","space":{"unit":"%","size":"","sizes":[]},"space_tablet":{"unit":"%","size":"","sizes":[]},"space_mobile":{"unit":"%","size":"","sizes":[]},"image_border_radius":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_space":{"unit":"px","size":0,"sizes":[]},"caption_space_tablet":{"unit":"px","size":"","sizes":[]},"caption_space_mobile":{"unit":"px","size":"","sizes":[]}}'
                                                                data-widget_type="site-logo.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <div className="hfe-site-logo">
                                                                        <a
                                                                            data-elementor-open-lightbox=""
                                                                            className="elementor-clickable"
                                                                            href="#"
                                                                        >
                                                                            <div className="hfe-site-logo-set">
                                                                                <div className="hfe-site-logo-container">
                                                                                    <img
                                                                                        decoding="async"
                                                                                        className="hfe-site-logo-img elementor-animation-"
                                                                                        src="/build/web/ez-bet/img/logo-black.png?v=1"
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="elementor-element elementor-element-b959d37 elementor-widget elementor-widget-image"
                                                                data-id="b959d37"
                                                                data-element_type="widget"
                                                                data-widget_type="image.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <img
                                                                        loading="lazy"
                                                                        decoding="async"
                                                                        width={100}
                                                                        height={100}
                                                                        src="/build/web/igame-index-lobby-wm/img/ic-nav-menu-hot-game.png"
                                                                        className="attachment-large size-large"
                                                                        alt=""
                                                                        srcSet="/build/web/igame-index-lobby-wm/img/ic-nav-menu-hot-game.png 100w, /build/web/igame-index-lobby-wm/img/ic-nav-menu-hot-game-24x24.png 24w, /build/web/igame-index-lobby-wm/img/ic-nav-menu-hot-game-36x36.png 36w, /build/web/igame-index-lobby-wm/img/ic-nav-menu-hot-game-48x48.png 48w"
                                                                        sizes="(max-width: 100px) 100vw, 100px"
                                                                    />{" "}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="elementor-element elementor-element-fb9397e elementor-widget elementor-widget-heading"
                                                                data-id="fb9397e"
                                                                data-element_type="widget"
                                                                data-widget_type="heading.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <h2 className="elementor-heading-title elementor-size-default">
                                                                        สมัครง่าย ไม่ยุ่งยาก
                                                                    </h2>{" "}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="elementor-element elementor-element-f2ec516 elementor-widget elementor-widget-text-editor"
                                                                data-id="f2ec516"
                                                                data-element_type="widget"
                                                                data-widget_type="text-editor.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <p>หากเกิดปัญหา</p>
                                                                    <p>มีทีมงานมืออาชีพ</p>
                                                                    <p>พร้อมดูแลคุณตลอด 24 ชั่วโมง</p>{" "}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="elementor-element elementor-element-e29871d elementor-widget elementor-widget-image"
                                                                data-id="e29871d"
                                                                data-element_type="widget"
                                                                data-widget_type="image.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <img
                                                                        loading="lazy"
                                                                        decoding="async"
                                                                        width={828}
                                                                        height={124}
                                                                        src="/build/web/igame-index-lobby-wm/img/ezgif-2-c6a36a52bb.gif"
                                                                        className="attachment-large size-large"
                                                                        alt=""
                                                                    />{" "}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                className="elementor-section elementor-top-section elementor-element elementor-element-3e023bf elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="3e023bf"
                                data-element_type="section"
                                data-settings='{"background_background":"classic"}'
                            >
                                {/* <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-66b980b"
                                        data-id="66b980b"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-e51c137 elementor-widget elementor-widget-heading"
                                                data-id="e51c137"
                                                data-element_type="widget"
                                                data-widget_type="heading.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-default">
                                                        โปรโมชั่นสุดพิเศษ
                                                    </h2>{" "}
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-4e10d3b uael-img-grid-tablet__column-4 uael-img-grid__column-4 uael-img-grid-mobile__column-2 uael-ins-normal elementor-widget elementor-widget-uael-image-gallery"
                                                data-id="4e10d3b"
                                                data-element_type="widget"
                                                data-settings='{"gallery_columns_tablet":"4","column_gap":{"unit":"px","size":10,"sizes":[]},"column_gap_mobile":{"unit":"px","size":5,"sizes":[]},"row_gap":{"unit":"px","size":10,"sizes":[]},"row_gap_mobile":{"unit":"px","size":5,"sizes":[]},"gallery_columns":"4","gallery_columns_mobile":"2","column_gap_tablet":{"unit":"px","size":"","sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]},"images_valign":"flex-start"}'
                                                data-widget_type="uael-image-gallery.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <div className="uael-gallery-parent uael-caption- uael-gallery-unjustified">
                                                        <div
                                                            className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                            data-filter-default="All"
                                                        >
                                                            <div className="uael-grid-item  uael-img-gallery-item-1">
                                                                <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                    <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                        <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                            <img
                                                                                loading="lazy"
                                                                                decoding="async"
                                                                                width={1040}
                                                                                height={1040}
                                                                                src="/build/web/igame-index-lobby-wm/img/ads01.jpg"
                                                                                className="attachment-full size-full"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="uael-grid-img-overlay" />
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                            <div className="uael-grid-item  uael-img-gallery-item-2">
                                                                <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                    <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                        <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                            <img
                                                                                loading="lazy"
                                                                                decoding="async"
                                                                                width={1040}
                                                                                height={1040}
                                                                                src="/build/web/igame-index-lobby-wm/img/ads02.jpg"
                                                                                className="attachment-full size-full"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="uael-grid-img-overlay" />
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                            <div className="uael-grid-item  uael-img-gallery-item-3">
                                                                <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                    <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                        <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                            <img
                                                                                loading="lazy"
                                                                                decoding="async"
                                                                                width={1040}
                                                                                height={1040}
                                                                                src="/build/web/igame-index-lobby-wm/img/ads03.jpg"
                                                                                className="attachment-full size-full"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="uael-grid-img-overlay" />
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                            <div className="uael-grid-item  uael-img-gallery-item-4">
                                                                <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                    <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                        <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                            <img
                                                                                loading="lazy"
                                                                                decoding="async"
                                                                                width={1040}
                                                                                height={1040}
                                                                                src="/build/web/igame-index-lobby-wm/img/ads04.jpg"
                                                                                className="attachment-full size-full"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="uael-grid-img-overlay" />
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                            <div className="uael-grid-item  uael-img-gallery-item-5">
                                                                <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                    <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                        <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                            <img
                                                                                loading="lazy"
                                                                                decoding="async"
                                                                                width={1040}
                                                                                height={1040}
                                                                                src="/build/web/igame-index-lobby-wm/img/ads05.jpg"
                                                                                className="attachment-full size-full"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="uael-grid-img-overlay" />
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                            <div className="uael-grid-item  uael-img-gallery-item-6">
                                                                <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                    <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                        <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                            <img
                                                                                loading="lazy"
                                                                                decoding="async"
                                                                                width={1040}
                                                                                height={1040}
                                                                                src="/build/web/igame-index-lobby-wm/img/ads06.jpg"
                                                                                className="attachment-full size-full"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="uael-grid-img-overlay" />
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                            <div className="uael-grid-item  uael-img-gallery-item-7">
                                                                <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                    <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                        <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                            <img
                                                                                loading="lazy"
                                                                                decoding="async"
                                                                                width={1040}
                                                                                height={1040}
                                                                                src="/build/web/igame-index-lobby-wm/img/ads07.jpg"
                                                                                className="attachment-full size-full"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="uael-grid-img-overlay" />
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                            <div className="uael-grid-item  uael-img-gallery-item-8">
                                                                <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                    <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                        <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                            <img
                                                                                loading="lazy"
                                                                                decoding="async"
                                                                                width={1040}
                                                                                height={1040}
                                                                                src="/build/web/igame-index-lobby-wm/img/ads08.jpg"
                                                                                className="attachment-full size-full"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="uael-grid-img-overlay" />
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>{" "}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </section>
                            <section
                                className="elementor-section elementor-inner-section elementor-element elementor-element-02c5561 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="02c5561"
                                data-element_type="section"
                                data-settings='{"background_background":"classic"}'
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f130389"
                                        data-id="f130389"
                                        data-element_type="column"
                                        data-settings='{"background_background":"classic"}'
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-b802f74 elementor-widget elementor-widget-text-editor"
                                                data-id="b802f74"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p className="p1">
                                                        <span className="s1" style={{ color: "#5a9bff" }}>
                                                            Trixie168{" "}
                                                        </span>
                                                        คาสิโนที่ใส่ใจคุณ
                                                    </p>
                                                    <p className="p1">สนุกได้ทั้งแนวตั้งและแนวนอน</p>{" "}
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-6c6b4fc elementor-widget elementor-widget-text-editor"
                                                data-id="6c6b4fc"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p className="p1">
                                                        <span className="s1">
                                                            Trixie168
                                                            <span className="Apple-converted-space">&nbsp; </span>
                                                        </span>
                                                        ใส่ใจทุกรายละเอียด ไม่ว่าคุณจะเล่นผ่านเว็บไซต์
                                                        <span className="s1">
                                                            <span className="Apple-converted-space">&nbsp;</span>
                                                        </span>
                                                        หรือ แอพในมือถือ ต่างได้ประสบการณ์สุดพิเศษ
                                                        <span className="s1">
                                                            <span className="Apple-converted-space">&nbsp;</span>
                                                        </span>
                                                        ที่ใช้งานง่าย เสถียร์ ให้คุณมีเวลา และ
                                                        ทำกำไรปราศจากความกังวลท่ามกลางเว็บ
                                                        <span className="s1"> Trixie168 Casino </span>มากมายเล่นทั้งที
                                                        ต้องเล่นเว็บตรง
                                                    </p>
                                                    <p className="p1">
                                                        <span className="s1">&gt; </span>เว็บตรงไม่โอนย้าย
                                                        สมัครภายใน<span className="s1"> 1 </span>นาที คุย
                                                        <span className="s1">/</span>ไม่คุย ก็เล่นได้ชิลๆ
                                                        <span className="s1">
                                                            <span className="Apple-converted-space">&nbsp;</span>
                                                        </span>
                                                    </p>
                                                    <p className="p1">
                                                        <span className="s1">&gt; </span>การเงินมั่นคงฝากถอน
                                                        ถอนสูงสุดบัญชีละ<span className="s1"> 2</span>ล้าน ต่อวัน
                                                        <span className="s1">
                                                            <span className="Apple-converted-space">&nbsp;</span>
                                                        </span>
                                                    </p>
                                                    <p className="p1">
                                                        <span className="s1">&gt; </span>ผู้นำตัวจริง ระบบออโต้ ฝาก
                                                        <span className="s1">–</span>ถอน การันตีเวลา
                                                        <span className="s1">
                                                            <span className="Apple-converted-space">&nbsp;</span>
                                                        </span>
                                                    </p>
                                                    <p className="p1">
                                                        <span className="s1">&gt; </span>ทีมงานมืออาชีพ
                                                        พร้อมดูแลคุณตลอด<span className="s1"> 24 </span>ชั่วโมง
                                                    </p>{" "}
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-08e82ed uael-img-grid__column-3 uael-img-grid-mobile__column-3 uael-img-grid-tablet__column-3 uael-ins-normal elementor-widget elementor-widget-uael-image-gallery"
                                                data-id="08e82ed"
                                                data-element_type="widget"
                                                data-settings='{"gallery_columns":"3","gallery_columns_mobile":"3","gallery_columns_tablet":"3","column_gap":{"unit":"px","size":20,"sizes":[]},"column_gap_tablet":{"unit":"px","size":"","sizes":[]},"column_gap_mobile":{"unit":"px","size":"","sizes":[]},"row_gap":{"unit":"px","size":20,"sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]},"row_gap_mobile":{"unit":"px","size":"","sizes":[]},"images_valign":"flex-start"}'
                                                data-widget_type="uael-image-gallery.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <div className="uael-gallery-parent uael-caption- uael-gallery-unjustified">
                                                        <div
                                                            className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                            data-filter-default="All"
                                                        >
                                                            <div className="uael-grid-item  uael-img-gallery-item-1">
                                                                <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                    <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                        <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                            <img
                                                                                loading="lazy"
                                                                                decoding="async"
                                                                                width={194}
                                                                                height={208}
                                                                                src="/build/web/igame-index-lobby-wm/img/test01.png"
                                                                                className="attachment-full size-full"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="uael-grid-img-overlay" />
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                            <div className="uael-grid-item  uael-img-gallery-item-2">
                                                                <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                    <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                        <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                            <img
                                                                                loading="lazy"
                                                                                decoding="async"
                                                                                width={194}
                                                                                height={208}
                                                                                src="/build/web/igame-index-lobby-wm/img/test02-1.png"
                                                                                className="attachment-full size-full"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="uael-grid-img-overlay" />
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                            <div className="uael-grid-item  uael-img-gallery-item-3">
                                                                <div className="uael-grid-item-content" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                    <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                        <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                            <img
                                                                                loading="lazy"
                                                                                decoding="async"
                                                                                width={194}
                                                                                height={208}
                                                                                src="/build/web/igame-index-lobby-wm/img/test03-1.png"
                                                                                className="attachment-full size-full"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="uael-grid-img-overlay" />
                                                                    </figure>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>{" "}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-710fcd5"
                                        data-id="710fcd5"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-ccafdf7 elementor-widget elementor-widget-image"
                                                data-id="ccafdf7"
                                                data-element_type="widget"
                                                data-widget_type="image.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <img
                                                        loading="lazy"
                                                        decoding="async"
                                                        width={715}
                                                        height={672}
                                                        src="/build/web/igame-index-lobby-wm/img/women-artgraphic.png"
                                                        className="attachment-large size-large"
                                                        alt=""
                                                        srcSet="/build/web/igame-index-lobby-wm/img/women-artgraphic.png 715w, /build/web/igame-index-lobby-wm/img/women-artgraphic-300x282.png 300w, /build/web/igame-index-lobby-wm/img/women-artgraphic-24x24.png 24w, /build/web/igame-index-lobby-wm/img/women-artgraphic-36x34.png 36w, /build/web/igame-index-lobby-wm/img/women-artgraphic-48x45.png 48w"
                                                        sizes="(max-width: 715px) 100vw, 715px"
                                                    />{" "}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                className="elementor-section elementor-top-section elementor-element elementor-element-4be68a2 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="4be68a2"
                                data-element_type="section"
                                data-settings='{"background_background":"classic"}'
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-74d0de3"
                                        data-id="74d0de3"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <section
                                                className="elementor-section elementor-inner-section elementor-element elementor-element-fccc68e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                data-id="fccc68e"
                                                data-element_type="section"
                                            >
                                                <div className="elementor-container elementor-column-gap-default">
                                                    <div
                                                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-213b8df"
                                                        data-id="213b8df"
                                                        data-element_type="column"
                                                    >
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div
                                                                className="elementor-element elementor-element-2ed8662 elementor-widget elementor-widget-image"
                                                                data-id="2ed8662"
                                                                data-element_type="widget"
                                                                data-widget_type="image.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <img
                                                                        loading="lazy"
                                                                        decoding="async"
                                                                        width={488}
                                                                        height={428}
                                                                        src="/build/web/igame-index-lobby-wm/img/file.png"
                                                                        className="attachment-large size-large"
                                                                        alt=""
                                                                        srcSet="/build/web/igame-index-lobby-wm/img/file.png 488w, /build/web/igame-index-lobby-wm/img/file-300x263.png 300w, /build/web/igame-index-lobby-wm/img/file-24x21.png 24w, /build/web/igame-index-lobby-wm/img/file-36x32.png 36w, /build/web/igame-index-lobby-wm/img/file-48x42.png 48w"
                                                                        sizes="(max-width: 488px) 100vw, 488px"
                                                                    />{" "}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ade28d4"
                                                        data-id="ade28d4"
                                                        data-element_type="column"
                                                    >
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div
                                                                className="elementor-element elementor-element-86a780a elementor-widget elementor-widget-text-editor"
                                                                data-id="86a780a"
                                                                data-element_type="widget"
                                                                data-widget_type="text-editor.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <p className="p1">
                                                                        ทำไมต้อง<span className="s1"> Trixie168</span>
                                                                    </p>{" "}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="elementor-element elementor-element-224f670 elementor-widget elementor-widget-text-editor"
                                                                data-id="224f670"
                                                                data-element_type="widget"
                                                                data-widget_type="text-editor.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <p className="p1" style={{ textAlign: "left" }}>
                                                                        <span className="s1">
                                                                            <img
                                                                                draggable="false"
                                                                                role="img"
                                                                                className="emoji"
                                                                                alt="👉"
                                                                                src="https://s.w.org/images/core/emoji/15.0.3/svg/1f449.svg"
                                                                            />{" "}
                                                                        </span>
                                                                        ระบบอัตโนมัติ ฝากถอนรวดเร็ว เพียงไม่กี่วินาที
                                                                    </p>
                                                                    <p className="p1" style={{ textAlign: "left" }}>
                                                                        <span className="s1">
                                                                            <img
                                                                                draggable="false"
                                                                                role="img"
                                                                                className="emoji"
                                                                                alt="👉"
                                                                                src="https://s.w.org/images/core/emoji/15.0.3/svg/1f449.svg"
                                                                            />{" "}
                                                                        </span>
                                                                        มีตัวตนจริง สถานที่ตั้งจริง ในคาสิโนต่างประเทศ
                                                                    </p>
                                                                    <p className="p1" style={{ textAlign: "left" }}>
                                                                        <span className="s1">
                                                                            <img
                                                                                draggable="false"
                                                                                role="img"
                                                                                className="emoji"
                                                                                alt="👉"
                                                                                src="https://s.w.org/images/core/emoji/15.0.3/svg/1f449.svg"
                                                                            />{" "}
                                                                        </span>
                                                                        เว็บเดียวจบครบทุกการเดิมพัน ไม่ว่าจะเป็น
                                                                        <span className="s1">
                                                                            <span className="Apple-converted-space">
                                                                                &nbsp;
                                                                            </span>
                                                                        </span>
                                                                        บาคาร่า สล็อต กีฬา เกม
                                                                    </p>
                                                                    <p className="p1" style={{ textAlign: "left" }}>
                                                                        <span className="s1">
                                                                            <img
                                                                                draggable="false"
                                                                                role="img"
                                                                                className="emoji"
                                                                                alt="👉"
                                                                                src="https://s.w.org/images/core/emoji/15.0.3/svg/1f449.svg"
                                                                            />{" "}
                                                                        </span>
                                                                        เปิดให้บริการมาไม่ต่ำกว่า
                                                                        <span className="s1"> 5 </span>ปี
                                                                    </p>
                                                                    <p className="p1" style={{ textAlign: "left" }}>
                                                                        <span className="s1">
                                                                            <img
                                                                                draggable="false"
                                                                                role="img"
                                                                                className="emoji"
                                                                                alt="👉"
                                                                                src="https://s.w.org/images/core/emoji/15.0.3/svg/1f449.svg"
                                                                            />{" "}
                                                                        </span>
                                                                        ฐานข้อมูลตั้งอยู่ในต่างประเทศ ปลอดภัย
                                                                        <span className="s1"> 100%</span>
                                                                    </p>
                                                                    <p className="p1" style={{ textAlign: "left" }}>
                                                                        <span className="s1">
                                                                            <img
                                                                                draggable="false"
                                                                                role="img"
                                                                                className="emoji"
                                                                                alt="👉"
                                                                                src="https://s.w.org/images/core/emoji/15.0.3/svg/1f449.svg"
                                                                            />{" "}
                                                                        </span>
                                                                        เล่นได้ ถอนเงินได้จริง สูงสุด
                                                                        <span className="s1"> 1,000,000 </span>ต่อวัน
                                                                    </p>
                                                                    <p className="p1" style={{ textAlign: "left" }}>
                                                                        <span className="s1">
                                                                            <img
                                                                                draggable="false"
                                                                                role="img"
                                                                                className="emoji"
                                                                                alt="👉"
                                                                                src="https://s.w.org/images/core/emoji/15.0.3/svg/1f449.svg"
                                                                            />{" "}
                                                                        </span>
                                                                        คาสิโนสด ส่งตรงจากคาสิโน ไม่มีการตัดต่อ
                                                                    </p>
                                                                    <p className="p1" style={{ textAlign: "left" }}>
                                                                        <span className="s2">
                                                                            <img
                                                                                draggable="false"
                                                                                role="img"
                                                                                className="emoji"
                                                                                alt="👉"
                                                                                src="https://s.w.org/images/core/emoji/15.0.3/svg/1f449.svg"
                                                                            />
                                                                        </span>{" "}
                                                                        ไม่มีขั้นต่ำ
                                                                    </p>{" "}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div
                                                className="elementor-element elementor-element-360f3b1 elementor-widget elementor-widget-text-editor"
                                                data-id="360f3b1"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        เว็บอันดับหนึ่ง Trixie168
                                                        ที่รวมสาวสวยมากมายให้ท่านได้ตื่นตาตื่นใจ บาคาร่าออนไลน์ที่
                                                        เย้ายวนใจที่สุดในตอนนี้ หรือ ที่รู้จักกันว่า&nbsp;
                                                    </p>
                                                    <p>
                                                        Sexy Baccarat, Sexy Gaming, เอ็มจีเอ็มห้าจี เซ็กซี่ หรือ
                                                        เซ็กซี่เกมส์ เอ็มจีเอ็มห้าจี
                                                    </p>{" "}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                className="elementor-section elementor-top-section elementor-element elementor-element-23c4309 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="23c4309"
                                data-element_type="section"
                                data-settings='{"background_background":"classic"}'
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5a60be3"
                                        data-id="5a60be3"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <section
                                                className="elementor-section elementor-inner-section elementor-element elementor-element-e664deb elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                data-id="e664deb"
                                                data-element_type="section"
                                            >
                                                <div className="elementor-container elementor-column-gap-default">
                                                    <div
                                                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-74c30c3"
                                                        data-id="74c30c3"
                                                        data-element_type="column"
                                                    >
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div
                                                                className="elementor-element elementor-element-f1198ec elementor-widget elementor-widget-heading"
                                                                data-id="f1198ec"
                                                                data-element_type="widget"
                                                                data-widget_type="heading.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <h3 className="elementor-heading-title elementor-size-default">
                                                                        บทความเกี่ยวกับคาสิโนออนไลน์
                                                                    </h3>{" "}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-01a5990"
                                                        data-id="01a5990"
                                                        data-element_type="column"
                                                    >
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div
                                                                className="elementor-element elementor-element-852a74e elementor-align-right elementor-widget elementor-widget-button"
                                                                data-id="852a74e"
                                                                data-element_type="widget"
                                                                data-widget_type="button.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <div className="elementor-button-wrapper">
                                                                        <a
                                                                            href="#"
                                                                            className="elementor-button-link elementor-button elementor-size-sm"
                                                                            role="button"
                                                                        >
                                                                            <span className="elementor-button-content-wrapper">
                                                                                <span className="elementor-button-icon elementor-align-icon-right">
                                                                                    <i
                                                                                        aria-hidden="true"
                                                                                        className="far fa-arrow-alt-circle-right"
                                                                                    />{" "}
                                                                                </span>
                                                                                <span className="elementor-button-text">
                                                                                    ดูทั้งหมด
                                                                                </span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div
                                                className="elementor-element elementor-element-a5fb217 elementor-posts--align-center elementor-grid-3 elementor-grid-tablet-2 elementor-grid-mobile-1 elementor-posts--thumbnail-top elementor-widget elementor-widget-posts"
                                                data-id="a5fb217"
                                                data-element_type="widget"
                                                data-settings='{"classic_row_gap":{"unit":"px","size":10,"sizes":[]},"classic_columns":"3","classic_columns_tablet":"2","classic_columns_mobile":"1","classic_row_gap_tablet":{"unit":"px","size":"","sizes":[]},"classic_row_gap_mobile":{"unit":"px","size":"","sizes":[]}}'
                                                data-widget_type="posts.classic"
                                            >
                                                <div className="elementor-widget-container">
                                                    <div className="elementor-posts-container elementor-posts elementor-posts--skin-classic elementor-grid elementor-has-item-ratio">
                                                        <article className="elementor-post elementor-grid-item post-783 post type-post status-publish format-standard has-post-thumbnail hentry category-post category-slot">
                                                            <a
                                                                className="elementor-post__thumbnail__link"
                                                                data-target="#loginmodal" data-dismiss="modal" data-toggle="modal"
                                                            >
                                                                <div className="elementor-post__thumbnail elementor-fit-height">
                                                                    <img
                                                                        loading="lazy"
                                                                        decoding="async"
                                                                        width={768}
                                                                        height={419}
                                                                        src="/build/web/igame-index-lobby-wm/img/batch_img20200420074597-768x419.jpg"
                                                                        className="attachment-medium_large size-medium_large"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </a>
                                                            <div className="elementor-post__text">
                                                                <h3 className="elementor-post__title">
                                                                    <a     href="#" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                        บาคาร่าGclub ค่ายดัง ทางเข้า
                                                                        เล่นเกมไพ่ยอดฮิตบนมือถือ พร้อมให้สมัคร{" "}
                                                                    </a>
                                                                </h3>
                                                                <div className="elementor-post__excerpt">
                                                                    <p>&nbsp;&nbsp;บาค</p>
                                                                </div>
                                                                <a
                                                                    href="#"
                                                                    className="elementor-post__read-more"
                                                                    data-target="#loginmodal" data-dismiss="modal" data-toggle="modal"
                                                                >
                                                                    อ่านบทความ{" "}
                                                                </a>
                                                            </div>
                                                        </article>
                                                        <article className="elementor-post elementor-grid-item post-764 post type-post status-publish format-standard has-post-thumbnail hentry category-post category-casino">
                                                            <a    href="#"
                                                                className="elementor-post__thumbnail__link"
                                                                data-target="#loginmodal" data-dismiss="modal" data-toggle="modal"
                                                            >
                                                                <div className="elementor-post__thumbnail elementor-fit-height">
                                                                    <img
                                                                        loading="lazy"
                                                                        decoding="async"
                                                                        width={768}
                                                                        height={419}
                                                                        src="/build/web/igame-index-lobby-wm/img/batch_img202004201030369-768x419.jpg"
                                                                        className="attachment-medium_large size-medium_large"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </a>
                                                            <div className="elementor-post__text">
                                                                <h3 className="elementor-post__title">
                                                                    <a     href="#" data-target="#loginmodal" data-dismiss="modal" data-toggle="modal">
                                                                        บาคาร่า777 ออนไลน์ เกมไพ่สุดฮิต ww สมัครเป็นสมาชิก
                                                                        รับฟรีเครดิต{" "}
                                                                    </a>
                                                                </h3>
                                                                <div className="elementor-post__excerpt">
                                                                    <p>&nbsp; &nbsp;&amp;n</p>
                                                                </div>
                                                                <a
                                                                href="#"
                                                                    className="elementor-post__read-more"
                                                                    data-target="#loginmodal" data-dismiss="modal" data-toggle="modal"
                                                                >
                                                                    อ่านบทความ{" "}
                                                                </a>
                                                            </div>
                                                        </article>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                className="elementor-section elementor-top-section elementor-element elementor-element-08dbad5 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="08dbad5"
                                data-element_type="section"
                                data-settings='{"background_background":"classic"}'
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6111bda"
                                        data-id="6111bda"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-5124d00 elementor-widget elementor-widget-heading"
                                                data-id="5124d00"
                                                data-element_type="widget"
                                                data-widget_type="heading.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-default">
                                                        -Q &amp; A-
                                                    </h2>{" "}
                                                </div>
                                            </div>
                                            <section
                                                className="elementor-section elementor-inner-section elementor-element elementor-element-15ee278 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                data-id="15ee278"
                                                data-element_type="section"
                                            >
                                                <div className="elementor-container elementor-column-gap-default">
                                                    <div
                                                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-92d26da"
                                                        data-id="92d26da"
                                                        data-element_type="column"
                                                    >
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div
                                                                className="elementor-element elementor-element-8fdafcf elementor-widget elementor-widget-toggle"
                                                                data-id="8fdafcf"
                                                                data-element_type="widget"
                                                                data-widget_type="toggle.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <div className="elementor-toggle" role="tablist">
                                                                        <div className="elementor-toggle-item">
                                                                            <div
                                                                                id="elementor-tab-title-1501"
                                                                                className="elementor-tab-title"
                                                                                data-tab={1}
                                                                                role="tab"
                                                                                aria-controls="elementor-tab-content-1501"
                                                                                aria-expanded="false"
                                                                            >
                                                                                <span
                                                                                    className="elementor-toggle-icon elementor-toggle-icon-left"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    <span className="elementor-toggle-icon-closed">
                                                                                        <i className="fas fa-caret-right" />
                                                                                    </span>
                                                                                    <span className="elementor-toggle-icon-opened">
                                                                                        <i className="elementor-toggle-icon-opened fas fa-caret-up" />
                                                                                    </span>
                                                                                </span>
                                                                                <a href="" className="elementor-toggle-title">
                                                                                    How to สมัครเล่นสล็อตออนไลน์ และวิธีการเล่น PG
                                                                                    SLOT สล็อตออนไลน์​ สมัคร​ง่าย ได้เงินไว
                                                                                </a>
                                                                            </div>
                                                                            <div
                                                                                id="elementor-tab-content-1501"
                                                                                className="elementor-tab-content elementor-clearfix"
                                                                                data-tab={1}
                                                                                role="tabpanel"
                                                                                aria-labelledby="elementor-tab-title-1501"
                                                                            >
                                                                                การเล่น&nbsp;สล็อตออนไลน์ สมัครเล่นสล็อตออนไลน์
                                                                                ถือได้ว่าเป็นหนึ่งในวิธีการเล่นการพนันออนไลน์​รวมถึงเป็นหนึ่งในรูปแบบของการเล่นการพนันออนไลน์​ในรูปแบบใหม่ด้วยเช่นกัน
                                                                                ซึ่ง เกมสล็อตออนไลน์
                                                                                ที่ผู้เขียนได้ทำการพูดถึงอยู่ในที่นี้นั้นก็คือเกมการพนันออนไลน์​ที่มีสถิติ​การเข้าถึงเป็นอันดับหนึ่งในสามจากเกมการพนันที่เป็นที่นิยมมากที่สุดจากการเล่นการพนันออนไลน์​อีกด้วย
                                                                                ดังนั้นผู้เขียนเชื่อว่าหลาย ๆ
                                                                                ท่านในที่นี้ต่างก็อาจจะอยากทำความรู้จักกับการ
                                                                                เล่นสล็อต ออนไลน์​กันเป็นอย่างมากแล้วอย่างแน่นอน
                                                                                ซึ่งในบทความนี้ผู้เขียนก็จะมาทำการกล่าวถึง
                                                                                ตู้สล็อตออนไลน์​ ที่มาพร้อมกับวิธีการ
                                                                                สมัครเล่นสล็อตออนไลน์
                                                                                และเมื่อผู้เขียนเอ่ยมาถึงจุดนี้แล้วหลาย ๆ
                                                                                ท่านต่างก็อาจจะคิดกันไปแล้วว่าวิธีการสมัคร​จะต้องยุ่งยากเป็นอย่างมาก
                                                                                หรือมีขั้นตอนที่เยอะเป็นอย่างมาก
                                                                                ทั้งที่ในความเป็นจริงแล้วทั้งวิธีการสมัครและวิธีการเล่นสล็อตออนไลน์​นี้ไม่ได้ยากเลยแม้แต่น้อย
                                                                                ดังนั้นถ้าหากว่าท่านใดกำลังสนใจที่จะเล่นเกมสล็อตออนไลน์​แต่ยังไม่รู้วิธีการเล่น
                                                                                หรือขั้นตอนใด ๆ
                                                                                เลยบทความนี้ก็อาจจะเป็นประโยชน์​ต่อท่านไม่มากก็น้อยก็ได้
                                                                            </div>
                                                                        </div>
                                                                        <div className="elementor-toggle-item">
                                                                            <div
                                                                                id="elementor-tab-title-1502"
                                                                                className="elementor-tab-title"
                                                                                data-tab={2}
                                                                                role="tab"
                                                                                aria-controls="elementor-tab-content-1502"
                                                                                aria-expanded="false"
                                                                            >
                                                                                <span
                                                                                    className="elementor-toggle-icon elementor-toggle-icon-left"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    <span className="elementor-toggle-icon-closed">
                                                                                        <i className="fas fa-caret-right" />
                                                                                    </span>
                                                                                    <span className="elementor-toggle-icon-opened">
                                                                                        <i className="elementor-toggle-icon-opened fas fa-caret-up" />
                                                                                    </span>
                                                                                </span>
                                                                                <a href="" className="elementor-toggle-title">
                                                                                    วิธีการเล่น สล็อต เครดิต ฟรี ไม่ ต้อง ฝาก ไม่
                                                                                    ต้อง แชร์ แค่ สมัค เล่นง่ายได้เงินเร็ว
                                                                                </a>
                                                                            </div>
                                                                            <div
                                                                                id="elementor-tab-content-1502"
                                                                                className="elementor-tab-content elementor-clearfix"
                                                                                data-tab={2}
                                                                                role="tabpanel"
                                                                                aria-labelledby="elementor-tab-title-1502"
                                                                            >
                                                                                สำหรับวิธีการเล่นสล็อตออนไลน์​นี้นั้นถือได้ว่ามีวิธีการเล่นและกติกาในการเล่นที่แทบจะไม่ได้แตกต่างไปจากการเล่นสล็อตแบบทั่ว
                                                                                ๆ ไปมากมายเท่าไหร่นัก
                                                                                แตกต่างก็เพียงแค่ว่ารูปแบบในการเล่นที่เป็นการเล่นผ่านทางระบบออนไลน์​ก็เท่านั้น
                                                                                โดยวิธีการเล่น ตู้สล็อตออนไลน์
                                                                                นั้นก็จะมีขั้นตอนต่าง ๆ ดังนี้ ขั้นตอนที่ 1
                                                                                เป็นขั้นตอนที่นักพนันทุก ๆ
                                                                                ท่านจะต้องทำการล็อกอินเข้่สู่ระบบหลังจากที่ได้ทำการสมัครบัญชี​ผู้ใช้งานเอาไว้แล้วตามที่ผู้เขียนได้กล่าวไปในข้างต้น
                                                                                ขั้นตอนที่ 2
                                                                                สำหรับขั้นตอนที่สองนี้ก็คือขั้นตอนต่อจากเมื่อที่นักพนันทุกท่านเข้าสู่ระบบและเข้าสู่หน้าเว็บไซต์​ของผู้ให้บริการ​เรียบร้อยแล้ว
                                                                                จากนั้นก็ให้ท่านเลือกเล่นเกมสล็อตในกรณีที่​ภายในเว็บไซต์​ของผู้ให้บริการมีเกมการพนันที่หลากหลาย
                                                                                จากนั้นเมื่อเลือกเกมสล็อตแล้วก็ให้ท่านเลือก ห้อง
                                                                                เครื่อง หรือตู้สล็อต ตามความสะดวกของท่าน
                                                                                ขั้นตอนที่ 3
                                                                                หลังจากที่ทุกท่านเข้าสู่ห้องหรือตู้สล็อตที่ท่านต้องการเข้าร่วมว่างเดิมพันเรียบร้อยแล้วสิ่งที่ท่านต้องทำต่อมานั่นก็คือการเลือก
                                                                                ไลน์ (แถวของสล็อต)
                                                                                และเลือกรูปแบบของสล็อตที่ตนเองต้องการวางเดิมพัน
                                                                                โดยในการวางเดิมพันอัตราผลตอบแทนก็อาจจะแตกต่างกันไปตามไลน์หรือแถวของสล็อตด้วยเช่นกัน
                                                                                จากนั้นก็เลือกจำนวนเงินในการวางเดิมพัน
                                                                                ตรวจสอบให้ดี กดวางเดิมพัน
                                                                                เพียงเท่านี้ก็เป็นอันเสร็จสิ้น
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ac66903"
                                                        data-id="ac66903"
                                                        data-element_type="column"
                                                    >
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div
                                                                className="elementor-element elementor-element-3bea6f9 elementor-widget elementor-widget-toggle"
                                                                data-id="3bea6f9"
                                                                data-element_type="widget"
                                                                data-widget_type="toggle.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <div className="elementor-toggle" role="tablist">
                                                                        <div className="elementor-toggle-item">
                                                                            <div
                                                                                id="elementor-tab-title-6281"
                                                                                className="elementor-tab-title"
                                                                                data-tab={1}
                                                                                role="tab"
                                                                                aria-controls="elementor-tab-content-6281"
                                                                                aria-expanded="false"
                                                                            >
                                                                                <span
                                                                                    className="elementor-toggle-icon elementor-toggle-icon-left"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    <span className="elementor-toggle-icon-closed">
                                                                                        <i className="fas fa-caret-right" />
                                                                                    </span>
                                                                                    <span className="elementor-toggle-icon-opened">
                                                                                        <i className="elementor-toggle-icon-opened fas fa-caret-up" />
                                                                                    </span>
                                                                                </span>
                                                                                <a href="" className="elementor-toggle-title">
                                                                                    สอนวิธีการ สมัครเล่นสล็อตออนไลน์ สล็อต PG
                                                                                    ทำได้ง่าย ๆ
                                                                                </a>
                                                                            </div>
                                                                            <div
                                                                                id="elementor-tab-content-6281"
                                                                                className="elementor-tab-content elementor-clearfix"
                                                                                data-tab={1}
                                                                                role="tabpanel"
                                                                                aria-labelledby="elementor-tab-title-6281"
                                                                            >
                                                                                สล็อต pg
                                                                                สำหรับในบทความนี้ผู้เขียนก็จะมาทำการสอนวิธีการสมัครเพื่อเล่นสล็อตออนไลน์​ง่าย
                                                                                ๆ ก็ในที่นี้
                                                                                ซึ่งผู้เขียนก็ต้องเอ่ยก่อนเลยว่าวิธีการสมัครเล่นสล็อตรูปแบบนี้นั้นมีวิธีการที่ไม่ได้ยุ่งยากใด
                                                                                ๆ
                                                                                แต่ท่านก็จำเป็นที่จะต้องใช้เอกสารส่วนตัวบางอย่างของท่านเพื่อผลประโยชน์​และสิทธิ​ของทุกท่านที่ใช้บริการ
                                                                                โดยขั้นตอนแรกของการสมัครเข้าใช้งานสล็อตออนไลน์​นี้นั้นก็คือการที่นักพนันทุก
                                                                                ๆ
                                                                                ท่านจะต้องเข้าไปที่หน้าเว็บไซต์​หรือหน้าแพลตฟอร์ม​ของผู้ให้บริการ
                                                                                จากนั้นก็กดหรือเลือกเมนูการสมัครสมาชิก
                                                                                ต่อมาทุกท่านก็มีความจำเป็นที่จะต้องกรอกข้อมูลส่วนตัวบางส่วนลงไปซึ่งท่านก็ไม่มีความจำเป็นที่จะต้องกังวลไปเนื่องจากว่าข้อมูลนี้ทางผู้ให้บริการจะไม่มีการนำมาเผยแพร่​สู่สาธารณะ​ใด
                                                                                ๆ ทั้งสิ้น และเมื่อทำการกรอกข้อมูลต่าง ๆ
                                                                                เสร็จเรียบร้อย​แล้วรอการตอบกลับจากผู้ดูแลระบบเพียงเท่านี้ก็ถือว่าการสมัครเข้าใช้งานสล็อตออนไลน์​เป็นอันเสร็จสิ้น​อย่างง่ายดายทันที
                                                                            </div>
                                                                        </div>
                                                                        <div className="elementor-toggle-item">
                                                                            <div
                                                                                id="elementor-tab-title-6282"
                                                                                className="elementor-tab-title"
                                                                                data-tab={2}
                                                                                role="tab"
                                                                                aria-controls="elementor-tab-content-6282"
                                                                                aria-expanded="false"
                                                                            >
                                                                                <span
                                                                                    className="elementor-toggle-icon elementor-toggle-icon-left"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    <span className="elementor-toggle-icon-closed">
                                                                                        <i className="fas fa-caret-right" />
                                                                                    </span>
                                                                                    <span className="elementor-toggle-icon-opened">
                                                                                        <i className="elementor-toggle-icon-opened fas fa-caret-up" />
                                                                                    </span>
                                                                                </span>
                                                                                <a href="" className="elementor-toggle-title">
                                                                                    {" "}
                                                                                    เล่นเกมได้เงิน เกม สล็อต ออนไลน์ ได้ เงิน จริง
                                                                                </a>
                                                                            </div>
                                                                            <div
                                                                                id="elementor-tab-content-6282"
                                                                                className="elementor-tab-content elementor-clearfix"
                                                                                data-tab={2}
                                                                                role="tabpanel"
                                                                                aria-labelledby="elementor-tab-title-6282"
                                                                            >
                                                                                สำหรับเกมสล็อตออนไลน์นี้สามารถ​สรุปได้คร่าว ๆ
                                                                                จากที่ผู้เขียนได้ทำการกล่าวถึงไปทั้งหมดในบทความนี้ได้ว่าเกมสล็อตออนไลน์​เป็นหนึ่งในเกมการพนันออนไลน์​ที่ได้รับความนิยมมากที่สุด
                                                                                อีกทั้งเกมสล็อตออนไลน์​นี้ก็ยังสามารถ​ที่จะตอบโจทย์​นักพนันในปัจจุบัน​ทุก
                                                                                ๆ ท่านได้เป็นอย่างดีอีกด้วย
                                                                                เนื่องจากว่าเกมสล็อตออนไลน์​หรือ
                                                                                ตู้สล็อตออนไลน์
                                                                                นี้นั้นถูกสร้างขึ้นมาจากความต้องการที่ว่าอยู่ที่ไหนก็สามารถ​ที่จะเล่นสล็อตออนไลน์​ได้
                                                                                ดังนั้นนี่จึงถือได้ว่าเป็นความสะดวก​สบาย​อันสูงสุดของนักพนันทุก
                                                                                ๆ ท่านก็ว่าได้ เนื่องจากว่าทุก ๆ
                                                                                ท่านไม่มีความจำเป็นเลยแม้แต่น้อยที่จะต้องเดินทางไปถึงบ่อนหรือคาสิโนให้เสียเวลา
                                                                                นอกจากนี้ในส่วนของวิธีการ สมัครเล่นสล็อตออนไลน์
                                                                                ก็ยังง่ายแสนง่ายด้วยวิธีการสมัคร 3 ขั้นตอน
                                                                                ตามที่ผู้เขียนได้ทำการกล่าวไว้ในข้างต้น
                                                                                ดั้งนั้นใคร ๆ ก็ทำได้และเข้าถึงได้อย่างแน่นอน
                                                                                และไม่ว่าจะเกิดปัญหา​ใด ๆ
                                                                                ขึ้นนักพนันทุกท่านก็ยังสามารถ​ที่จะติดต่อทางผู้ดูแลระบบได้โดยตรงอีกด้วย
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                className="elementor-section elementor-top-section elementor-element elementor-element-908289f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="908289f"
                                data-element_type="section"
                                data-settings='{"background_background":"gradient"}'
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-01e41b9"
                                        data-id="01e41b9"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated e-swiper-container">
                                            <div
                                                className="elementor-element elementor-element-82bf8e2 elementor-widget elementor-widget-image-carousel e-widget-swiper"
                                                data-id="82bf8e2"
                                                data-element_type="widget"
                                                data-settings='{"slides_to_show":"8","slides_to_show_tablet":"5","slides_to_show_mobile":"4","navigation":"none","autoplay_speed":1000,"image_spacing_custom":{"unit":"px","size":25,"sizes":[]},"autoplay":"yes","pause_on_hover":"yes","pause_on_interaction":"yes","infinite":"yes","speed":500}'
                                                data-widget_type="image-carousel.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <div
                                                        className="elementor-image-carousel-wrapper swiper-container swiper-container-initialized swiper-container-horizontal"
                                                        dir="ltr"
                                                    >
                                                        <div
                                                            className="elementor-image-carousel swiper-wrapper"
                                                            style={{
                                                                transform: "translate3d(-2146.88px, 0px, 0px)",
                                                                transitionDuration: "0ms"
                                                            }}
                                                        >
                                                            <div
                                                                className="swiper-slide swiper-slide-duplicate"
                                                                data-swiper-slide-index={4}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/dream-gaming.d993e67.png"
                                                                        alt="dream-gaming.d993e67"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide swiper-slide-duplicate"
                                                                data-swiper-slide-index={5}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/gamatron.96cdfab.png"
                                                                        alt="gamatron.96cdfab"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
                                                                data-swiper-slide-index={6}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/joker-gaming.417d073.png"
                                                                        alt="joker-gaming.417d073"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                                                                data-swiper-slide-index={7}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/live-22.dfd4972.png"
                                                                        alt="live-22.dfd4972"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
                                                                data-swiper-slide-index={8}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/pretty-gaming.f27ce57.png"
                                                                        alt="pretty-gaming.f27ce57"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide swiper-slide-duplicate"
                                                                data-swiper-slide-index={9}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/sa-gaming.52e386a.png"
                                                                        alt="sa-gaming.52e386a"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide swiper-slide-duplicate"
                                                                data-swiper-slide-index={10}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/sexy-gaming.09728c1.png"
                                                                        alt="sexy-gaming.09728c1"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide swiper-slide-duplicate"
                                                                data-swiper-slide-index={11}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/spade-gaming.d835b4d.png"
                                                                        alt="spade-gaming.d835b4d"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide"
                                                                data-swiper-slide-index={0}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/ag-gaming.2eb0369.png"
                                                                        alt="ag-gaming.2eb0369"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide"
                                                                data-swiper-slide-index={1}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/ambbet.1d53780.png"
                                                                        alt="ambbet.1d53780"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide"
                                                                data-swiper-slide-index={2}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/ameba.dea3fdf.png"
                                                                        alt="ameba.dea3fdf"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide"
                                                                data-swiper-slide-index={3}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/dragoonsoft.a39781a.png"
                                                                        alt="dragoonsoft.a39781a"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide"
                                                                data-swiper-slide-index={4}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/dream-gaming.d993e67.png"
                                                                        alt="dream-gaming.d993e67"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide"
                                                                data-swiper-slide-index={5}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/gamatron.96cdfab.png"
                                                                        alt="gamatron.96cdfab"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide swiper-slide-prev"
                                                                data-swiper-slide-index={6}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/joker-gaming.417d073.png"
                                                                        alt="joker-gaming.417d073"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide swiper-slide-active"
                                                                data-swiper-slide-index={7}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/live-22.dfd4972.png"
                                                                        alt="live-22.dfd4972"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide swiper-slide-next"
                                                                data-swiper-slide-index={8}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/pretty-gaming.f27ce57.png"
                                                                        alt="pretty-gaming.f27ce57"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide"
                                                                data-swiper-slide-index={9}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/sa-gaming.52e386a.png"
                                                                        alt="sa-gaming.52e386a"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide"
                                                                data-swiper-slide-index={10}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/sexy-gaming.09728c1.png"
                                                                        alt="sexy-gaming.09728c1"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide"
                                                                data-swiper-slide-index={11}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/spade-gaming.d835b4d.png"
                                                                        alt="spade-gaming.d835b4d"
                                                                    />
                                                                </figure>
                                                            </div>{" "}
                                                            <div
                                                                className="swiper-slide swiper-slide-duplicate"
                                                                data-swiper-slide-index={0}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/ag-gaming.2eb0369.png"
                                                                        alt="ag-gaming.2eb0369"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide swiper-slide-duplicate"
                                                                data-swiper-slide-index={1}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/ambbet.1d53780.png"
                                                                        alt="ambbet.1d53780"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide swiper-slide-duplicate"
                                                                data-swiper-slide-index={2}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/ameba.dea3fdf.png"
                                                                        alt="ameba.dea3fdf"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide swiper-slide-duplicate"
                                                                data-swiper-slide-index={3}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/dragoonsoft.a39781a.png"
                                                                        alt="dragoonsoft.a39781a"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide swiper-slide-duplicate"
                                                                data-swiper-slide-index={4}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/dream-gaming.d993e67.png"
                                                                        alt="dream-gaming.d993e67"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide swiper-slide-duplicate"
                                                                data-swiper-slide-index={5}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/gamatron.96cdfab.png"
                                                                        alt="gamatron.96cdfab"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
                                                                data-swiper-slide-index={6}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/joker-gaming.417d073.png"
                                                                        alt="joker-gaming.417d073"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div
                                                                className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                                                                data-swiper-slide-index={7}
                                                                style={{ width: "118.125px", marginRight: 25 }}
                                                            >
                                                                <figure className="swiper-slide-inner">
                                                                    <img
                                                                        decoding="async"
                                                                        className="swiper-slide-image"
                                                                        src="/build/web/igame-index-lobby-wm/img/live-22.dfd4972.png"
                                                                        alt="live-22.dfd4972"
                                                                    />
                                                                </figure>
                                                            </div>
                                                        </div>
                                                        <span
                                                            className="swiper-notification"
                                                            aria-live="assertive"
                                                            aria-atomic="true"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                className="elementor-section elementor-top-section elementor-element elementor-element-7177db0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="7177db0"
                                data-element_type="section"
                                data-settings='{"background_background":"gradient"}'
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-3d4a575"
                                        data-id="3d4a575"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-a1c39f7 imgbgcolor elementor-widget elementor-widget-site-logo"
                                                data-id="a1c39f7"
                                                data-element_type="widget"
                                                data-settings='{"width":{"unit":"px","size":215,"sizes":[]},"width_tablet":{"unit":"px","size":"","sizes":[]},"width_mobile":{"unit":"px","size":"","sizes":[]},"align":"center","space":{"unit":"%","size":"","sizes":[]},"space_tablet":{"unit":"%","size":"","sizes":[]},"space_mobile":{"unit":"%","size":"","sizes":[]},"image_border_radius":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_space":{"unit":"px","size":0,"sizes":[]},"caption_space_tablet":{"unit":"px","size":"","sizes":[]},"caption_space_mobile":{"unit":"px","size":"","sizes":[]}}'
                                                data-widget_type="site-logo.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <div className="hfe-site-logo">
                                                        <a
                                                            data-elementor-open-lightbox=""
                                                            className="elementor-clickable"
                                                            href="#"
                                                        >
                                                            <div className="hfe-site-logo-set">
                                                                <div className="hfe-site-logo-container">
                                                                    <img
                                                                        decoding="async"
                                                                        className="hfe-site-logo-img elementor-animation-"
                                                                        src="/build/web/ez-bet/img/logo-black.png?v=1"
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-8b59e55 elementor-widget elementor-widget-text-editor"
                                                data-id="8b59e55"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p style={{ textAlign: "center" }}>
                                                        Trixie168 เว็บคาสิโนออนไลน์ ครองอันดับในใจคนไทย 10
                                                        ปีซ้อนTrixie168 บาคาร่า คาสิโนออนไลน์ ที่ดีที่สุด
                                                        เพื่อประสบการณ์ที่ดีของผู้เล่นอย่างแท้จริง แบบ Trixie168
                                                    </p>{" "}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-da07d1f"
                                        data-id="da07d1f"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-776b356 elementor-widget elementor-widget-heading"
                                                data-id="776b356"
                                                data-element_type="widget"
                                                data-widget_type="heading.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-default">
                                                        คาสิโนที่ดีที่สุด
                                                    </h2>{" "}
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-fffabaa elementor-align-left elementor-mobile-align-center elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                                data-id="fffabaa"
                                                data-element_type="widget"
                                                data-widget_type="icon-list.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <ul className="elementor-icon-list-items">
                                                        <li className="elementor-icon-list-item">
                                                            <span className="elementor-icon-list-icon">
                                                                <i aria-hidden="true" className="fas fa-dot-circle" />{" "}
                                                            </span>
                                                            <span className="elementor-icon-list-text">
                                                                คาสิโนออนไลน์
                                                            </span>
                                                        </li>
                                                        <li className="elementor-icon-list-item">
                                                            <span className="elementor-icon-list-icon">
                                                                <i aria-hidden="true" className="fas fa-dot-circle" />{" "}
                                                            </span>
                                                            <span className="elementor-icon-list-text">
                                                                สล็อตออนไลน์
                                                            </span>
                                                        </li>
                                                        <li className="elementor-icon-list-item">
                                                            <span className="elementor-icon-list-icon">
                                                                <i aria-hidden="true" className="fas fa-dot-circle" />{" "}
                                                            </span>
                                                            <span className="elementor-icon-list-text">
                                                                แทงบอลออนไลน์
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-f310ece"
                                        data-id="f310ece"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-9e1ded5 elementor-widget elementor-widget-heading"
                                                data-id="9e1ded5"
                                                data-element_type="widget"
                                                data-widget_type="heading.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-default">
                                                        สล็อตที่ดีที่สุด
                                                    </h2>{" "}
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-4612cc3 elementor-align-left elementor-mobile-align-center elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                                data-id="4612cc3"
                                                data-element_type="widget"
                                                data-widget_type="icon-list.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <ul className="elementor-icon-list-items">
                                                        <li className="elementor-icon-list-item">
                                                            <span className="elementor-icon-list-icon">
                                                                <i aria-hidden="true" className="fas fa-dot-circle" />{" "}
                                                            </span>
                                                            <span className="elementor-icon-list-text">
                                                                คาสิโนออนไลน์
                                                            </span>
                                                        </li>
                                                        <li className="elementor-icon-list-item">
                                                            <span className="elementor-icon-list-icon">
                                                                <i aria-hidden="true" className="fas fa-dot-circle" />{" "}
                                                            </span>
                                                            <span className="elementor-icon-list-text">
                                                                สล็อตออนไลน์
                                                            </span>
                                                        </li>
                                                        <li className="elementor-icon-list-item">
                                                            <span className="elementor-icon-list-icon">
                                                                <i aria-hidden="true" className="fas fa-dot-circle" />{" "}
                                                            </span>
                                                            <span className="elementor-icon-list-text">
                                                                แทงบอลออนไลน์
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-ea16d90"
                                        data-id="ea16d90"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-76c4c5d elementor-widget elementor-widget-heading"
                                                data-id="76c4c5d"
                                                data-element_type="widget"
                                                data-widget_type="heading.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-default">
                                                        แทงบอลมาแรง
                                                    </h2>{" "}
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-505f039 elementor-align-left elementor-mobile-align-center elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
                                                data-id="505f039"
                                                data-element_type="widget"
                                                data-widget_type="icon-list.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <ul className="elementor-icon-list-items">
                                                        <li className="elementor-icon-list-item">
                                                            <span className="elementor-icon-list-icon">
                                                                <i aria-hidden="true" className="fas fa-dot-circle" />{" "}
                                                            </span>
                                                            <span className="elementor-icon-list-text">
                                                                คาสิโนออนไลน์
                                                            </span>
                                                        </li>
                                                        <li className="elementor-icon-list-item">
                                                            <span className="elementor-icon-list-icon">
                                                                <i aria-hidden="true" className="fas fa-dot-circle" />{" "}
                                                            </span>
                                                            <span className="elementor-icon-list-text">
                                                                สล็อตออนไลน์
                                                            </span>
                                                        </li>
                                                        <li className="elementor-icon-list-item">
                                                            <span className="elementor-icon-list-icon">
                                                                <i aria-hidden="true" className="fas fa-dot-circle" />{" "}
                                                            </span>
                                                            <span className="elementor-icon-list-text">
                                                                แทงบอลออนไลน์
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </footer>
                </div>
                <div className=" x-modal modal loginmodaldiv modaldiv"
                    id="loginmodal"
                    tabIndex={-1}
                    role="dialog"
                    aria-modal="true"

                >
                    <div className="contentmodal animate__animated animate__bounceInDown">
                        <button className="closeloginbtn" data-dismiss="modal" aria-label="Close"><i className="fas fa-times" /></button>
                        <div className="login">
                            <a href="/" className="custom-logo-link" rel="home"><img width={400} height={110} src="/build/web/ez-bet/img/logo-black.png?v=1" className="custom-logo" alt="BLUEWHITE"
                                decoding="async" srcSet="/build/web/ez-bet/img/logo-black.png?v=1 400w, /build/web/ez-bet/img/logo-black.png?v=1 300w, /build/web/ez-bet/img/logo-black.png?v=1 24w, /build/web/ez-bet/img/logo-black.png?v=1 36w, /build/web/ez-bet/img/logo-black.png?v=1 48w" sizes="(max-width: 400px) 100vw, 400px" /></a>            <h5>เข้าสู่ระบบ</h5>

                            <input type="hidden" name="url" defaultValue />
                            <div className="form-login">
                                <div className>
                                    <div className="input-group mb-3">
                                        <input type="text"
                                            id="username"
                                            inputMode="text"
                                            onChange={this.onChange}
                                            name="username"
                                            pattern="[0-9]*"
                                            autoFocus=""
                                            className="form-control" /></div>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="password"
                                        id="password"
                                        name="password"
                                        onChange={this.onChange}
                                        placeholder="รหัสผ่าน"
                                        className="form-control" /></div>
                                <button type="submit" className="btn btn-lg btn-login login" onClick={(e) => {
                                    this.login(e)
                                }}  >เข้าสู่ระบบ</button>
                            </div>

                            <div className="footer-form py-3"><div className="row "><div className="col-7"><span className="text-white">ท่านยังไม่มีบัญชี?</span> <a href="#" className="text-white"><u>สมัครสมาชิก</u></a></div> <div className="col-5 text-right"><a href="#" target="_blank" className="text-decoration-none text-white">ลืมรหัสผ่าน ?</a></div></div></div>
                        </div>
                    </div>
                    <div className="overlaymodal" />
                </div>

            </>

        );
    }
}

export default Main;
