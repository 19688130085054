
import './App.css';
import React, { Component } from 'react'; //different
import instance from "./axios-instance";
import Systems from "./SystemFunction";

class Main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pathname: "",
      linelink: ""
    }
  }
  async componentDidMount() {
    this.setState({
      pathname: window.location.pathname.replace("/", "").trim()
    });
    await instance.post("/api/v1/mainsetting", {
      System: Systems,
    }).then((res) => {
      if (res.data.status === 200) {
        const datas = res.data.message;
        this.setState({
          linelink: datas[1].value
        });
      }
      else {
        // this.CheckThisGoalError();
      }
    });
  }
  render() {
    const pathname = this.state.pathname
    return (
      <div className="tabgamemenu">
        <div className="menu-menu-left-container">
          <ul id="menu-menu-left" className="menu">
            <li
              id="menu-item-402"
              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-402"
            >
              <a
                href="/sport"
                className="menu-image-title-after menu-image-not-hovered"
              >
                <img
                  width={300}
                  height={300}
                  src="/build/web/igame-index-lobby-wm/img/Artboard-5-300x300-1.png"
                  className="menu-image menu-image-title-after"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">กีฬา</span>
              </a>
            </li>
            <li
              id="menu-item-1102"
              className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-1101 current_page_item menu-item-1102"
            >
              <a
                href="/slot"
                aria-current="page"
                className="menu-image-title-after menu-image-not-hovered"
              >
                <img
                  width={300}
                  height={300}
                  src="/build/web/igame-index-lobby-wm/img/slot-1.png"
                  className="menu-image menu-image-title-after"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">สล็อต</span>
              </a>
            </li>
            <li
              id="menu-item-1108"
              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1108"
            >
              <a
                href="/casino"
                className="menu-image-title-after menu-image-not-hovered"
              >
                <img
                  width={300}
                  height={300}
                  src="/build/web/igame-index-lobby-wm/img/casino.png"
                  className="menu-image menu-image-title-after"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  คาสิโน
                </span>
              </a>
            </li>
            <li
              id="menu-item-1808"
              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1808"
            >
              <a
                href="/promotions"
                className="menu-image-title-after menu-image-not-hovered"
              >
                <img
                  width={300}
                  height={300}
                  src="/build/web/igame-index-lobby-wm/img/pro.png"
                  className="menu-image menu-image-title-after"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  โปรโมชั่น
                </span>
              </a>
            </li>
            <li
              id="menu-item-1809"
              className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1809"
            >
              <a href={this.state.linelink} className="menu-image-title-after menu-image-not-hovered">
                <img
                  width={300}
                  height={300}
                  src="/build/web/igame-index-lobby-wm/img/call.png"
                  className="menu-image menu-image-title-after"
                  alt=""
                  decoding="async"
                />
                <span className="menu-image-title-after menu-image-title">
                  ติดต่อ
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>

    );
  }
}
export default Main;

